const { isGigPaused } = require('../../utils/gigStatus');
const {
    ALLOWED_CATEGORIES,
    ALLOWED_SUB_CATEGORIES,
    ALLOWED_CURRENT_USER_STATUSES,
    RESTRICTED_GIG_STATUS,
} = require('./constants');

/**
 * @param {number} categoryId The gig category id
 * @param {number} subCategoryId The gig subcategory id
 * @return {boolean} is in allowed custom order category
 */
const inAllowedCategory = (categoryId, subCategoryId) =>
    ALLOWED_CATEGORIES.includes(categoryId) || ALLOWED_SUB_CATEGORIES.includes(subCategoryId);

const shouldRenderCustomOrder = ({ userId, general, customOrder }) => {
    const { gigStatus, isStudio, categoryId, subCategoryId, traffiqed, isOnVacation, isLimitedEdition } = general;
    const { currentUserStatus, allowCustomOrders } = customOrder;

    return (
        inAllowedCategory(categoryId, subCategoryId) &&
        isAllowedUserStatus({ userId, currentUserStatus }) &&
        !RESTRICTED_GIG_STATUS.includes(gigStatus) &&
        allowCustomOrders &&
        !isGigPaused(gigStatus, traffiqed) &&
        !isStudio &&
        !isOnVacation &&
        !isLimitedEdition
    );
};

/**
 * @param {string} userId
 * @param {string} currentUserStatus
 *
 * @returns {boolean} Whether the user status is allowed.
 * In case the user is a guest return true by default.
 */
const isAllowedUserStatus = ({ userId, currentUserStatus }) => {
    if (!userId) {
        return true;
    }

    return ALLOWED_CURRENT_USER_STATUSES.includes(currentUserStatus);
};

module.exports = {
    inAllowedCategory,
    isAllowedUserStatus,
    shouldRenderCustomOrder,
};
