import { useRef } from 'react';
import { useGigPageContext } from '@fiverr-private/gig_page_context';
import { useEnteredView } from '@fiverr-private/hooks';
import { useExperiments } from '../../hooks/useExperiments';
import { reportContactButtonImpression } from '../../utils/contactSeller';
import { CONTACT_OPTIONS_TYPE, AI_BRIEF_CONTACT_OPTIONS_TYPE } from './constants';

export interface UseContactButtonImpressionOptions {
    elementType: string;
    checkRecentInteractions: () => Promise<boolean>;
}

type ContactSellerTargetType = 'inbox' | 'ask_a_question_drawer' | 'ai_brief_drawer';

const resolveElementType = (elementType: string, inAIBriefTest: boolean) => {
    if (inAIBriefTest) {
        if (elementType === CONTACT_OPTIONS_TYPE.DROPDOWN) return AI_BRIEF_CONTACT_OPTIONS_TYPE.DROPDOWN;
    }
    return elementType;
};

export const useContactButtonImpression = ({
    elementType,
    checkRecentInteractions,
}: UseContactButtonImpressionOptions) => {
    const { biEvents } = useGigPageContext();
    const observableRef = useRef<HTMLDivElement>(null);
    const { inAIBriefTest } = useExperiments();
    const resolveElementName = async (): Promise<ContactSellerTargetType> => {
        const hadRecentInteractions = await checkRecentInteractions();
        if (inAIBriefTest) {
            return hadRecentInteractions ? 'inbox' : 'ai_brief_drawer';
        }
        return hadRecentInteractions ? 'inbox' : 'ask_a_question_drawer';
    };

    const resolvedElementType = resolveElementType(elementType, inAIBriefTest);

    useEnteredView(observableRef, {
        threshold: 1,
        onEntered: async () => {
            reportContactButtonImpression({
                biEvents,
                params: { elementType: resolvedElementType, elementName: await resolveElementName() },
            });
        },
    });

    return observableRef;
};
