import React, { useRef } from 'react';
import { Stack } from '@fiverr-private/layout_components';
import { useGigPageContext } from '@fiverr-private/gig_page_context';
import { Avatar, AvatarImage } from '@fiverr-private/avatar';
import { ModalType, openFipMigrationModal } from '@fiverr-private/pro_migration';
import { Text } from '@fiverr-private/typography';
import { I18n } from '@fiverr-private/i18n-react';
import { getContext } from '@fiverr-private/fiverr_context';
import { buildRedirectQueryParameters, BUSINESS_TRIGGER_CTA } from '@fiverr-private/business_success_sign_modal';
import { useEnteredView } from '@fiverr-private/hooks';
import { ContactTrigger } from '../shared/ContactTrigger';
import { HOURLY_CONTACT_BUTTON } from '../../utils/events';
import { reportHourlyCardClick, reportHourlyCardImpression } from '../../utils/biEvents/bianka';
import { isLoggedInUser } from '../../utils/isLoggedInUser';
import { INITIAL_TEXT, TRANSLATION_KEY } from './consts';
import { IContactHourlySellerCardProps } from './types';

export const ContactHourlySellerCard: React.FC<IContactHourlySellerCardProps> = ({ containerStyles, className }) => {
  const { sellerCard, seller } = useGigPageContext();
  const { organizationId } = getContext();

  const isCurrentUserPro = !!organizationId;
  const observableRef = useRef<HTMLDivElement>(null);

  useEnteredView(observableRef, {
    threshold: 0,
    onEntered: reportHourlyCardImpression,
  });

  const openProMigrationModal = () => {
    openFipMigrationModal({
      variant: ModalType.SeamlessHourly,
      payload: {
        source: 'hourly_gig_request',
        biEnrichment: {
          pageElementName: 'hourly_gig_request',
        },
        onSuccess: () => {
          const url = buildRedirectQueryParameters({
            redirectUrl: getContext().url,
            source: 'hourly_gig_request',
            type: BUSINESS_TRIGGER_CTA.DEFAULT,
          });
          window?.open(url, '_self');
        },
      },
    });
  };

  const handleClick = (openContact: () => void) => {
    if (isCurrentUserPro || !isLoggedInUser()) return openContact();
    openProMigrationModal();
  };

  return (
    <ContactTrigger
      onClick={reportHourlyCardClick}
      shouldOpenAiBrief
      aiBriefProps={{ initialText: INITIAL_TEXT }}
      source={HOURLY_CONTACT_BUTTON}
    >
      {({ onClick }) => (
        <Stack
          borderColor="grey_400"
          borderRadius="md"
          borderWidth="sm"
          padding="6"
          cursor="pointer"
          direction="column"
          {...containerStyles}
          className={className}
          ref={observableRef}
          onClick={() => handleClick(onClick)}
        >
          <Stack>
            <Avatar size="sm" shape="circle" username={seller?.username || ''} showRing={false}>
              <AvatarImage src={sellerCard?.profilePhoto} alt={seller?.username || ''} />
            </Avatar>
            <Stack direction="column" gap="0">
              <Text size="b_sm" fontWeight="bold">
                <I18n k={`${TRANSLATION_KEY}.title`} />
              </Text>
              <Text size="b_sm" decoration="underline">
                <I18n k={`${TRANSLATION_KEY}.subtitle`} />
              </Text>
            </Stack>
          </Stack>
          <Text size="b_sm" color="bodySecondary">
            <I18n k={`${TRANSLATION_KEY}.description`} />
          </Text>
        </Stack>
      )}
    </ContactTrigger>
  );
};
