import React from 'react';
import { FlagIcon } from '@fiverr-private/visuals';
import { Text } from '@fiverr-private/typography';
import { Stack } from '@fiverr-private/layout_components';
import { I18n } from '@fiverr-private/i18n-react';
import { useGigPageContext } from '@fiverr-private/gig_page_context';
import { ReportAnIssueButton } from '@fiverr-private/content_reporting';

interface ReportGigItemProps {
  onClick: () => void;
}

const CARIBOU_GIG_REPORT_ELIGIBILITY_ROLLOUT = 'caribou_gig_report_eligibility';

export const ReportGigItem: React.FC<ReportGigItemProps> = ({ onClick }) => {
  const { rollouts } = useGigPageContext();
  const isInRollout = rollouts[CARIBOU_GIG_REPORT_ELIGIBILITY_ROLLOUT];

  return isInRollout ? (
    <ReportAnIssueButton variant="button" shouldTriggerAuth shouldTriggerActivation />
  ) : (
    <Stack
      paddingY="2"
      paddingX="4"
      alignItems="center"
      cursor="pointer"
      backgroundColor={{ hover: 'grey_200' }}
      onClick={onClick}
    >
      <FlagIcon />
      <Text>
        <I18n k="gig_page_nav.action_ctas.report_this_gig.cta" />
      </Text>
    </Stack>
  );
};
