/**
 * @desc subCategory ids for gigs that are non-experiential
 */
export const NON_EXP_GIGS_SUB_CATEGORIES = new Set([
    21, 23, 25, 26, 28, 30, 31, 59, 65, 68, 73, 77, 78, 97, 105, 107, 108, 109, 112, 114, 115, 120, 121, 123, 127, 142,
    144, 145, 146, 147, 163, 169, 175, 200, 287, 326, 334, 335, 336, 337, 338, 341, 347, 352, 353, 354, 355, 380, 381,
    382, 388, 389, 393, 394, 395, 396, 397, 398, 404, 408, 409, 413, 416, 423, 425, 430, 435, 436, 442, 443, 446, 449,
    451, 452, 453, 455, 456, 457, 464, 469, 470, 471, 475, 477, 479, 487, 488, 489, 490, 501, 510, 517, 524, 529, 533,
    538, 539, 540, 541, 542, 543, 544, 550, 566, 568, 574, 584, 585, 598, 599, 602, 603, 605, 606, 607, 614, 615, 616,
    617, 618, 619, 620, 622, 623,
]);
