import React from 'react';
import { bool, number } from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { useGigPageContext } from '@fiverr-private/gig_page_context';
import { isTriplePackage } from '../../../../reducers/packages';
import { PACKAGES_TABLE } from '../../../../utils/pageSections';
import { shouldShowSelectPackage } from '../../../../utils/shouldShowSelectPackage';
import { useExperiments } from '../../../../hooks/useExperiments';
import CompareBtn from './CompareBtn';
import CtaBtn from './CtaBtn';

import './style.scss';

const TabFooter = ({ showCompareAnchor = true, currentPrice }) => {
    const { general, currencyConverter, sectionScroller } = useGigPageContext();
    const isGigActive = shouldShowSelectPackage(general);
    const formattedPrice = currencyConverter.convertWithFee(currentPrice);
    const { inHourlyIndicationExperiment } = useExperiments();

    if (!isGigActive && !showCompareAnchor) {
        return null;
    }

    const wrapperClass = classNames('tab-footer');

    const onComparePackagesClick = () => sectionScroller.scroll({ sectionKey: PACKAGES_TABLE });

    return (
        <footer className={wrapperClass}>
            {isGigActive && <CtaBtn price={formattedPrice} />}
            {showCompareAnchor && !inHourlyIndicationExperiment && (
                <CompareBtn onComparePackagesClick={onComparePackagesClick} />
            )}
        </footer>
    );
};

TabFooter.propTypes = {
    showCompareAnchor: bool,
    currentPrice: number,
};

const mapStateToProps = (state) => {
    const { packages } = state;
    return {
        showCompareAnchor: isTriplePackage(packages),
    };
};

export { TabFooter };
export default connect(mapStateToProps)(TabFooter);
