import React from 'react';
import { connect } from 'react-redux';
import { bool } from 'prop-types';
import { useGigPageContext } from '@fiverr-private/gig_page_context';
import { isTriplePackage } from '../../../../../reducers/packages';
import { PACKAGES_TABLE } from '../../../../../utils/pageSections';
import CompareBtn from '../../TabFooter/CompareBtn';
import { useExperiments } from '../../../../../hooks/useExperiments';

const ComparePackagesFooterInfo = ({ showCompareAnchor = false }) => {
    const { sectionScroller } = useGigPageContext();
    const { inHourlyIndicationExperiment } = useExperiments();

    const onComparePackagesClick = () => sectionScroller.scroll({ sectionKey: PACKAGES_TABLE });

    return (
        showCompareAnchor &&
        !inHourlyIndicationExperiment && <CompareBtn onComparePackagesClick={onComparePackagesClick} />
    );
};

ComparePackagesFooterInfo.propTypes = {
    showCompareAnchor: bool,
};

const mapStateToProps = ({ packages }) => ({
    showCompareAnchor: isTriplePackage(packages),
});

export { ComparePackagesFooterInfo };
export default connect(mapStateToProps)(ComparePackagesFooterInfo);
