import { getContext } from '@fiverr-private/fiverr_context';
import { triggerSignUp as triggerSignUpHandler } from '@fiverr-private/identification_triggers';
import { metric } from '../metric';
import {
    BQ_CLICKED_CONTACT_SELLER,
    BQ_CLICKED_ON_CONTACT_SELLER_OPTIONS,
    BQ_CLICKED_ON_STICKY_CHAT_OPTION,
    BQ_SIGNUP_CLICK,
    BQ_VIEWED_CONTACT_ME_BUTTON,
} from '../bigQueryEvents';
import {
    MP_CLICKED_CONTACT_ME,
    MP_CLICKED_ON_STICKY_CHAT_OPTION,
    MP_CONTACT_SELLER_OPTIONS_CLICK,
    MP_CONTACT_SELLER_OPTIONS_SEND_MESSAGE_SUBMIT_SUCCESS,
} from '../mixPanelEvents';

const PAGE_NAME = 'gig_page';

/**
 * Trigger join modal (or redirects to join page in mobile) on guest's click on contact seller button.
 * @param {object} biEvents
 * @param {object} pathfinderWrapper
 * @param {object} modalOptions
 */
export const onGuestClick = async ({ biEvents, pathfinderWrapper, modalOptions = {} }) => {
    // The event BQ_SIGNUP_CLICK is reported here because we open the signUpModal manually
    // and not via the autoShowSignUp API
    biEvents.sendBigQueryEvent({ eventName: BQ_SIGNUP_CLICK });

    return triggerSignUp({ pathfinderWrapper, modalOptions });
};

const triggerSignUp = async ({ pathfinderWrapper, modalOptions }) => {
    try {
        await triggerSignUpHandler({
            modalOptions: {
                ...modalOptions,
                triggerType: 'blocked_action',
            },
        });
    } catch {
        window.location.assign(pathfinderWrapper.pathfinder('join'));
    }
};

/**
 * Reports a click on the contact seller button.
 * @param {object} biEvents
 * @param {object} pathfinderWrapper
 * @param {string} mixpanelSourceComponent
 * @param {string} bqSourceName
 * @param {string} metricKey
 */
export const reportContactSellerClick = ({ biEvents, mixpanelSourceComponent, bqSourceName, metricKey }) => {
    biEvents.sendMixPanelEvent({
        eventName: MP_CLICKED_CONTACT_ME,
        params: { 'Source component': mixpanelSourceComponent },
    });

    biEvents.sendBigQueryEvent({
        eventName: BQ_CLICKED_CONTACT_SELLER,
        params: {
            name: bqSourceName,
            eventSource: 'contact_seller_direct',
        },
    });

    metric.count(metricKey);
};

/**
 * Reports success in sending a message
 * @param {object} biEvents
 */
export const reportSendMessageSubmitSuccess = (biEvents) => {
    biEvents.sendMixPanelEvent({
        eventName: MP_CONTACT_SELLER_OPTIONS_SEND_MESSAGE_SUBMIT_SUCCESS,
    });
};

/**
 * Reports the contact seller options click event
 * @param {object} biEvents
 * @param {string} eventSource The component's source name
 */
export const reportContactSellerOptionsClick = (biEvents, eventSource) => {
    biEvents.sendMixPanelEvent({
        eventName: MP_CONTACT_SELLER_OPTIONS_CLICK,
    });

    biEvents.sendBigQueryEvent({
        eventName: BQ_CLICKED_ON_CONTACT_SELLER_OPTIONS,
        params: { name: eventSource },
    });
};

/**
 * Reports click on floating chat button
 * @param {object} biEvents
 * @param {object} params
 * @param {boolean} params.isOnline - is seller online
 * @param {number} params.responseTime - seller response time in hours
 */
export const reportStickyChatClick = ({ biEvents, params }) => {
    biEvents.sendMixPanelEvent({
        eventName: MP_CLICKED_ON_STICKY_CHAT_OPTION,
    });

    biEvents.sendBigQueryEvent({
        eventName: BQ_CLICKED_ON_STICKY_CHAT_OPTION,
        params,
    });
};

export const reportContactButtonImpression = ({ biEvents, params }) => {
    biEvents?.sendBigQueryImpression({
        eventName: BQ_VIEWED_CONTACT_ME_BUTTON,
        params: { elementType: params.elementType, pageName: PAGE_NAME, elementName: params.elementName },
    });
};

/**
 * Redirects to an inbox conversation
 * @param {object} pathfinderWrapper
 */
export const redirectToInboxConversation = (pathfinderWrapper) => {
    const { pathParameters } = getContext();
    const { username } = pathParameters;
    const inboxConversationUrl = pathfinderWrapper.pathfinder('conversation', {
        id: username,
    });
    window.location.assign(inboxConversationUrl);
};
