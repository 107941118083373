import React from 'react';
import { bool, node, string } from 'prop-types';
import { Container } from '@fiverr-private/layout_components';
import { useGigPageContext } from '@fiverr-private/gig_page_context';
import { reportContactSellerClick } from '../../../utils/contactSeller';
import ContactTrigger from '../../shared/ContactTrigger';
import { GradientBorderButton } from '../../shared/GradientBorderButton/GradientBorderButton';
import {contactSellerTabButtonStyle} from "../ContactSellerOptions/ContactButtonStyles/TabContent";

const ContactSellerBtn = ({
    asContactSellerOption,
    mixpanelSourceComponent,
    bqSourceName,
    triggerId,
    triggerCopy,
    triggerPlacement,
    children,
}) => {
    const { biEvents, isFloatingChatEnabled } = useGigPageContext();

    const onClick = () =>
        reportContactSellerClick({
            biEvents,
            mixpanelSourceComponent,
            bqSourceName,
            metricKey: 'contact_seller.click',
        });

    return (
        <Container textAlign="center">
            <ContactTrigger
                className={contactSellerTabButtonStyle}
                source="contact-btn"
                onClick={onClick}
                bqSourceName={bqSourceName}
                triggerId={triggerId}
                triggerCopy={triggerCopy}
                triggerPlacement={triggerPlacement}
            >
                {(triggerProps) =>
                    asContactSellerOption ? (
                        <Container {...triggerProps}>{children}</Container>
                    ) : (
                        <GradientBorderButton {...triggerProps} variant="outline" fullWidth withBorder={isFloatingChatEnabled}>
                            {children}
                        </GradientBorderButton>
                    )
                }
            </ContactTrigger>
        </Container>
    );
};

ContactSellerBtn.propTypes = {
    asContactSellerOption: bool,
    mixpanelSourceComponent: string,
    bqSourceName: string,
    children: node,
    triggerId: string,
    triggerCopy: string,
    triggerPlacement: string,
};

export default ContactSellerBtn;
