import React, { Component } from 'react';
import { shape, arrayOf, oneOfType, string, object } from 'prop-types';
import _ from 'lodash';
import { getContext } from '@fiverr-private/fiverr_context';
import { I18n } from '@fiverr-private/i18n-react';
import { DEFAULT_FIVERR_LOCALE } from '@fiverr-private/localization';
import SafePureComponent from '../shared/SafePureComponent';
import { buildTagUrl } from '../../utils/tag';
import { LOGO_DESIGN_SUBCATEGORY_ID, LOGO_MAKER_TAG } from './constants';

import './style.scss';

class Tags extends Component {
    validateTags(tags) {
        if (!tags) {
            return false;
        }

        const { tagsGigList } = tags;
        return !_.isEmpty(tagsGigList);
    }

    getEnrichedTags() {
        const { tags } = this.props;
        const { subCategoryId } = this.context.general;
        const { locale } = getContext();
        const shouldEnrichWithLMTag = subCategoryId === LOGO_DESIGN_SUBCATEGORY_ID && locale === DEFAULT_FIVERR_LOCALE;

        if (shouldEnrichWithLMTag) {
            const { tagsGigList = [] } = tags || {};

            return { ...tags, tagsGigList: [...tagsGigList, LOGO_MAKER_TAG] };
        }

        return tags;
    }

    createFormattedTagsList(tags) {
        const { pathfinderWrapper, logger } = this.context;
        const { tagsGigList } = tags;
        const sanitizedTagsGigList = tagsGigList.filter(({ name, formattedClassification, slug }) => {
            if (formattedClassification.type === 'tagPage' && !slug) {
                logger.warn(`Tag "${name}" is corrupted `, { formattedClassification, name, slug });

                return false;
            }

            if (formattedClassification.key) {
                return true;
            }

            logger.warn(`Tag "${name}" is corrupted `, { formattedClassification, name, slug });

            return false;
        });

        return sanitizedTagsGigList.map(({ name, slug, formattedClassification }) => ({
            text: name,
            url: buildTagUrl(pathfinderWrapper, { slug, formattedClassification }),
        }));
    }

    render() {
        const enrichedTags = this.getEnrichedTags();
        if (!this.validateTags(enrichedTags)) {
            return null;
        }

        const tagsList = this.createFormattedTagsList(enrichedTags);

        return (
            <div className="gig-tags-container">
                <h2 className="section-title">
                    <I18n k={'gig_page_perseus.tags.title'} />
                </h2>
                <ul>
                    {tagsList.map((tag) => (
                        <li key={tag.text}>
                            <a href={tag.url}>{tag.text}</a>
                        </li>
                    ))}
                </ul>
            </div>
        );
    }
}

Tags.propTypes = {
    tags: shape({
        tagsGigList: arrayOf(
            shape({
                name: string,
                slug: string,
                formattedClassification: shape({
                    type: string,
                    key: oneOfType([string, object]),
                }),
            })
        ),
    }).isRequired,
};

Tags.contextTypes = {
    pathfinderWrapper: object,
    logger: object,
    general: object,
};

export { Tags };
export default SafePureComponent(Tags);
