var __defProp = Object.defineProperty;
var __typeError = (msg) => {
  throw TypeError(msg);
};
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
var __accessCheck = (obj, member, msg) => member.has(obj) || __typeError("Cannot " + msg);
var __privateGet = (obj, member, getter) => (__accessCheck(obj, member, "read from private field"), getter ? getter.call(obj) : member.get(obj));
var __privateAdd = (obj, member, value) => member.has(obj) ? __typeError("Cannot add the same private member more than once") : member instanceof WeakSet ? member.add(obj) : member.set(obj, value);
var __privateSet = (obj, member, value, setter) => (__accessCheck(obj, member, "write to private field"), setter ? setter.call(obj, value) : member.set(obj, value), value);

// src/beforeHide/index.ts
function beforeHide(callback) {
  document.addEventListener(
    "visibilitychange",
    () => document.visibilityState === "hidden" && callback()
  );
  document.addEventListener("beforeunload", callback, { once: true });
  document.addEventListener("pagehide", callback, { once: true });
}

// src/eventStore/index.ts
import { assign } from "@recursive/assign";

// package.json
var name = "@fiverr-private/relay-sdk";
var version = "1.7.0";

// src/eventStore/index.ts
var EVENT_STORES = Symbol.for(
  [
    name.split("/").pop(),
    // Name with namespace removed
    version.split(".").shift()
    // Major version
  ].join("_")
);
var EventStore = class {
  /**
   * Create a new event store to store events and contexts
   * Grouped by event type, these events can be sent to the relay API in batches
   */
  constructor() {
    __publicField(this, "events");
    __publicField(this, "contexts");
    this.events = Object.freeze({
      rum: [],
      monicore: [],
      log: [],
      stat: []
    });
    this.contexts = {};
  }
  /**
   * Return the events and contexts as a single object, then empty the event store
   */
  flush() {
    const { pendingRecordTypes } = this;
    if (pendingRecordTypes.length === 0) return null;
    const payload = pendingRecordTypes.reduce(
      (accumulator, type) => Object.assign(accumulator, {
        [type]: [...this.events[type]].map((entry) => {
          entry._age = performance.now() - (entry._enteredAt ?? 0);
          delete entry._enteredAt;
          return entry;
        })
      }),
      {
        context: assign(
          {},
          ...pendingRecordTypes.map((type) => this.contexts[type])
        )
      }
    );
    Object.keys(this.events).forEach((type) => {
      this.events[type].length = 0;
    });
    return payload;
  }
  /**
   * Add an event to the store by type
   */
  add(type, entries2) {
    entries2.forEach((entry) => {
      entry._enteredAt = performance.now();
    });
    this.events[type].push(...entries2);
    return this;
  }
  /**
   * Add a context to the store by type
   */
  setContext(type, context) {
    this.contexts[type] = this.contexts[type] || {};
    Object.assign(this.contexts[type], context);
    return this;
  }
  /**
   * Which event types have pending records
   */
  get pendingRecordTypes() {
    return Object.keys(this.events).filter(
      (key) => this.events[key].length > 0
    );
  }
  /**
   * Get the current batch size
   */
  get batchSize() {
    return Object.entries(this.events).reduce(
      (accumulator, [_, events]) => accumulator + events.length,
      0
    );
  }
};
function getEventStore(pageId) {
  globalThis[EVENT_STORES] || (globalThis[EVENT_STORES] = /* @__PURE__ */ new Map());
  if (globalThis[EVENT_STORES].has(pageId))
    return globalThis[EVENT_STORES].get(pageId);
  const eventStore = new EventStore();
  globalThis[EVENT_STORES].set(pageId, eventStore);
  return eventStore;
}

// src/entries/index.ts
var entries = typeof Object.entries === "function" ? Object.entries : (target) => Object.keys(target).map((key) => [key, target[key]]);

// src/describe/index.ts
function describe(element) {
  if (!element) return;
  if (typeof element === "string") return element;
  try {
    return [nodeType(element), contents(element)].filter(Boolean).join(" ") || `${element}`;
  } catch (error) {
    return `${element}`;
  }
}
var nodeType = (element) => typeof element.nodeType === "number" ? entries(Node).find(
  ([, enumerator]) => enumerator === element.nodeType
)[0] : void 0;
function contents(element) {
  if (element.nodeType === Node.TEXT_NODE) return element.nodeValue;
  if (typeof element.nodeName !== "string") return void 0;
  return querySelector(element);
}
function querySelector(element) {
  return [].reduce.call(
    element.attributes,
    (accumulator, { name: name2, value }) => `${accumulator}[${name2.toLowerCase()}${value ? `^="${value.substring(0, 50)}"` : ""}]`,
    element.nodeName.toLowerCase()
  );
}

// src/stringify/index.ts
function stringify(target) {
  try {
    return JSON.stringify(target, replacer);
  } catch (error) {
    setTimeout(() => {
      error.source = [`${target}`, typeof target].join(" ");
      throw error;
    });
    return void 0;
  }
}
function replacer(_, value) {
  if (value instanceof Element) {
    return describe(value);
  }
  return value;
}

// src/sender/index.ts
var _endpoint;
var Sender = class {
  constructor({ endpoint }) {
    __privateAdd(this, _endpoint);
    __privateSet(this, _endpoint, endpoint);
  }
  // This interface returns a promise so that we can change it to a fetch request or a websocket request in the future if desired
  // It should, however, be a fire-and-forget operation
  async send(payload) {
    const body = stringify(payload);
    if (body === void 0) return false;
    if (body === "[object Object]") {
      setTimeout(() => {
        const error = new SyntaxError(
          [
            "Failed to stringify payload",
            `(${payload})`,
            typeof payload,
            typeof payload === "object" ? Object.keys(payload) : ""
          ].filter(Boolean).join(" ")
        );
        Object.assign(error, {
          code: "PAYLOAD_STRINGIFY",
          url: location.href
        });
        throw error;
      });
      return false;
    }
    return globalThis?.navigator?.sendBeacon?.(__privateGet(this, _endpoint), body);
  }
};
_endpoint = new WeakMap();

// src/index.ts
var _sender, _eventStore, _maxBatchSize, _maxBatchInterval, _timer, _localMode;
var RelaySDK = class {
  constructor({
    id,
    endpoint,
    maxBatchSize = 25,
    maxBatchInterval = 5e3,
    mode = "production"
  }) {
    __privateAdd(this, _sender);
    __privateAdd(this, _eventStore);
    __privateAdd(this, _maxBatchSize);
    __privateAdd(this, _maxBatchInterval);
    __privateAdd(this, _timer, null);
    __privateAdd(this, _localMode);
    __privateSet(this, _sender, new Sender({ endpoint }));
    __privateSet(this, _maxBatchSize, maxBatchSize);
    __privateSet(this, _maxBatchInterval, maxBatchInterval);
    __privateSet(this, _eventStore, getEventStore(id));
    __privateSet(this, _localMode, typeof mode === "string" ? /^(?:dev|test|local)/i.test(mode) : false);
    beforeHide(this.send.bind(this));
  }
  /**
   * Send the current batch of events to the Relay API
   * The same eventStore may be used by multiple instances of the SDK
   */
  async send() {
    __privateGet(this, _timer) && clearTimeout(__privateGet(this, _timer));
    const payload = __privateGet(this, _eventStore).flush();
    __privateSet(this, _timer, null);
    if (!payload) return false;
    if (__privateGet(this, _localMode)) {
      console.debug(`[RelaySDK] flush`, payload);
      return true;
    }
    return await __privateGet(this, _sender).send(payload);
  }
  /**
   * Add one of the supported event types to the event store
   */
  addEntries(type, entries2) {
    __privateGet(this, _eventStore).add(type, entries2);
    if (__privateGet(this, _eventStore).batchSize >= __privateGet(this, _maxBatchSize)) {
      this.send();
      return this;
    }
    if (__privateGet(this, _timer) === null) {
      __privateSet(this, _timer, setTimeout(this.send.bind(this), __privateGet(this, _maxBatchInterval)));
    }
    return this;
  }
  /**
   * Add a RUM performance record to be sent with the next batch
   */
  rum(...entries2) {
    return this.addEntries("rum", entries2);
  }
  /**
   * Add a Monicore record to be sent with the next batch
   */
  monicore(...entries2) {
    return this.addEntries("monicore", entries2);
  }
  /**
   * Add a log record to be sent with the next batch
   */
  log(...entries2) {
    return this.addEntries("log", entries2);
  }
  /**
   * Add a stat record to be sent with the next batch.
   * If a stat entry has a `rate` property, it will be sent with a probability equal to `rate` to reduce the number of events sent.
   */
  stat(...entries2) {
    return this.addEntries(
      "stat",
      entries2.filter(
        (entry) => typeof entry.rate !== "number" || Math.random() < entry.rate
      ).map((entry) => {
        if (entry.type === "time" && entry.value instanceof Date) {
          entry.value = Date.now() - entry.value.getTime();
        }
        return entry;
      })
    );
  }
  /**
   * Add contextual information to a specific type.
   * These details will be added to calls including one or more of this event.
   */
  registerContext(type, context) {
    __privateGet(this, _eventStore).setContext(type, context);
    return this;
  }
};
_sender = new WeakMap();
_eventStore = new WeakMap();
_maxBatchSize = new WeakMap();
_maxBatchInterval = new WeakMap();
_timer = new WeakMap();
_localMode = new WeakMap();
__publicField(RelaySDK, "SUPPORTED_EVENT_TYPES", [
  "rum",
  "monicore",
  "log",
  "stat"
]);
export {
  RelaySDK
};
