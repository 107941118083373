import React from 'react';
import { bool } from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { Container } from '@fiverr-private/layout_components';
import SafePureComponent from '../shared/SafePureComponent';
import { isSinglePackage } from '../../reducers/packages';
import { useExperiments } from '../../hooks/useExperiments';
import { tabButtonContentContainerStyle } from '../ContactSeller/ContactSellerOptions/ContactButtonStyles/TabContent/TabContent.ve.css';
import SinglePackage from './SinglePackage';
import TriplePackage from './TriplePackage';
import './style.scss';

const PackagesTabs = ({ shouldRender = true, isSinglePackage }) => {
    const { inHourlyIndicationExperiment } = useExperiments();

    if (!shouldRender) {
        return null;
    }

    return (
        <Container
            className={
                inHourlyIndicationExperiment
                    ? classNames('hourly-indications', tabButtonContentContainerStyle)
                    : 'default'
            }
        >
            {isSinglePackage ? <SinglePackage /> : <TriplePackage />}
        </Container>
    );
};

PackagesTabs.propTypes = {
    shouldRender: bool,
    isSinglePackage: bool,
};

const mapStateToProps = ({ packages, customOrder }) => ({
    shouldRender: packages.packageList.length > 0,
    isSinglePackage: isSinglePackage(packages),
    customOrder,
});

export { PackagesTabs };
export default SafePureComponent(connect(mapStateToProps)(PackagesTabs));
