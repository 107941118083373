import React from 'react';
import { noop } from 'lodash';
import { EllipsisIcon } from '@fiverr-private/visuals';
import { Popover, PopoverAnchor, PopoverChevron, PopoverContent } from '@fiverr-private/popover';
import { Stack } from '@fiverr-private/layout_components';
import { useGigPageContext } from '@fiverr-private/gig_page_context';
import { ENTITY_TYPE, LazyReportContentModal, ReportingContextProvider } from '@fiverr-private/content_reporting';
import { ActionCtaBase } from '../ActionCtaBase';
import { useGigPageNavContext } from '../../GigPageNavContext/GigPageNavContext';
import { ReportGigItem } from './ReportGigItem';

export const ShowMoreCta: React.FC = () => {
  const {
    currentUser,
    general: { gigId, sellerId },
  } = useGigPageContext();
  const { ReportGigButtonWrapper } = useGigPageNavContext();

  const isRestricted = currentUser?.status === 'restricted';

  if (isRestricted) {
    return null;
  }

  // This is a temp solution to solve clicking outside a Popover on a modal.
  // When refactoring ReportGig component, to use the new modal - add to PopoverContent the prop: renderWhenClosed
  // and all this logic will be redundant
  return (
    <ReportingContextProvider entityId={`${gigId}`} entityType={ENTITY_TYPE.GIG} sellerId={`${sellerId}`}>
      <ReportGigButtonWrapper>
        {({ onReportGigItemClick }) => (
          <Popover position="bottom">
            <PopoverAnchor>
              <ActionCtaBase onClick={noop} dataTestId="show_more_test_id">
                <EllipsisIcon />
              </ActionCtaBase>
            </PopoverAnchor>
            <PopoverContent disablePadding>
              <PopoverChevron />
              <Stack margin="2" direction="column">
                <ReportGigItem onClick={onReportGigItemClick} />
              </Stack>
            </PopoverContent>
          </Popover>
        )}
      </ReportGigButtonWrapper>
      <LazyReportContentModal />
    </ReportingContextProvider>
  );
};
