import { trackEvent } from '@fiverr-private/gtm';

const GIG_PAGE_TRACKING_EVENTS = {
    GIG_PAGE_LOAD_EVENT: 'gig_page_load',
    WORKFLOW_GIG_PAGE_RECORDING_REQUESTED_EVENT: 'workflow-gig-page-recording-requested',
    CONTACT_ME_BUTTON_CLICK_EVENT: 'contact_me_clicked',
};

export const trackWorkflowGigPageRecordingRequested = () =>
    trackEvent(GIG_PAGE_TRACKING_EVENTS.WORKFLOW_GIG_PAGE_RECORDING_REQUESTED_EVENT);

interface TrackGigPageLoadParams {
    gigId: string;
    gigTitle: string;
    isPro: boolean;
    categorySlug: string;
    subCategorySlug: string;
    nestedSubCategorySlug: string;
    basePrice: number;
}

export const trackGigPageLoad = (params: TrackGigPageLoadParams) =>
    trackEvent(GIG_PAGE_TRACKING_EVENTS.GIG_PAGE_LOAD_EVENT, params);

export const trackContactMeClickEvent = () => trackEvent(GIG_PAGE_TRACKING_EVENTS.CONTACT_ME_BUTTON_CLICK_EVENT);
