import { getContext } from '@fiverr-private/fiverr_context';
import experiments from '../utils/experiments/experimentsEnum';

const EXPERIMENT_GROUP = 2;
const SUPPORTED_LANGUAGES = ['en-US'];

export const useExperiments = () => {
    const { abTests, locale } = getContext();
    const isUserLanguageSupported = SUPPORTED_LANGUAGES.includes(locale);

    return {
        inAIBriefTest:
            isUserLanguageSupported &&
            abTests[experiments.ZEBRAS_CREATE_AI_BRIEF_ON_GIG_PAGE_EXPERIMENT] === EXPERIMENT_GROUP,
        inHourlyIndicationExperiment: abTests[experiments.ZEBRAS_MP_HOURLY_FILTER] === EXPERIMENT_GROUP,
    };
};
