import React from 'react';
import { useGigPageContext } from '@fiverr-private/gig_page_context';
import { Container, Stack } from '@fiverr-private/layout_components';
import { I18n } from '@fiverr-private/i18n-react';
import { Text, Heading, Typography } from '@fiverr-private/typography';
import SellerTranslationBadgeWrapper from '../../SellerTranslationBadgeWrapper';
import { useShowPromotionDiscountedPriceBIEvent } from '../hooks/useShowPromotionDiscountedPriceBIEvent';
import PriceTooltipIcon from '../PriceTooltipIcon';
import { HeaderProps } from './types';
import './style.scss';

const HeaderDefault: React.FC<HeaderProps> = ({
  title,
  showPrice,
  formattedPrice,
  description,
  showPriceTooltip,
  discountedPriceSection,
}) => {
  useShowPromotionDiscountedPriceBIEvent(!!discountedPriceSection);
  const { shouldDisplayFees } = useGigPageContext();
  const titleMarginBottom = discountedPriceSection ? '6' : '0';
  const pricePaddingLeft = shouldDisplayFees ? '0' : '2.5';

  return (
    <header className="header-default">
      <Heading
        style={shouldDisplayFees ? { display: 'grid', marginBottom: '24px' } : undefined}
        minHeight="48px"
        as="h3"
        marginBottom={titleMarginBottom}
      >
        {title && (
          <Typography marginBottom="2" flex="1" size="b_md" as="b" fontWeight="bold">
            <SellerTranslationBadgeWrapper translationKey="packages">{title}</SellerTranslationBadgeWrapper>
          </Typography>
        )}
        {showPrice && (
          <Container alignItems="flexEnd" paddingLeft={pricePaddingLeft}>
            <Stack as="span" gap="0" alignItems="center">
              <Container>
                <Text as="span">{formattedPrice}</Text>
                {shouldDisplayFees && (
                  <Text paddingLeft="1.5" color="bodySecondary" as="span">
                    <I18n k="gig_page_perseus.packages.plus_fees" />
                  </Text>
                )}
              </Container>
              {showPriceTooltip && <PriceTooltipIcon />}
            </Stack>
            {discountedPriceSection && <Typography marginTop="1">{discountedPriceSection}</Typography>}
          </Container>
        )}
      </Heading>
      {description && <Typography as="p">{description}</Typography>}
    </header>
  );
};

export default HeaderDefault;
