import React from 'react';
import { I18n } from '@fiverr-private/i18n-react';
import { HourlyIcon } from '@fiverr-private/visuals';
import { Container, Wrap } from '@fiverr-private/layout_components';
import { Text } from '@fiverr-private/typography';
import { Seller } from '../../types/seller';
import { useExperiments } from '../../hooks/useExperiments';
import { useGetSellerEligibility } from './useGetSellerEligibility';

interface OffersHourlyRatesProps {
  seller: Seller;
}

export const OffersHourlyRatesHighlight: React.FC<OffersHourlyRatesProps> = ({ seller }) => {
  const { inHourlyIndicationExperiment } = useExperiments();
  const showHourlyRate = useGetSellerEligibility(seller) && !inHourlyIndicationExperiment;

  if (!showHourlyRate) {
    return null;
  }

  return (
    <Container display="flex" gap="2" paddingY="1">
      <Wrap flex="0" alignItems="center">
        <HourlyIcon size="sm" color="grey_1200" />
      </Wrap>
      <Wrap alignItems="center">
        <Text fontWeight="semibold" size="b_sm">
          <I18n k="gig_page_perseus.offers_hourly_rates.highlight.title" />
        </Text>
      </Wrap>
    </Container>
  );
};
