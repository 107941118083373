import React from 'react';
import { Avatar, AvatarImage } from '@fiverr-private/avatar';

export interface SellerAvatarProps {
  username: string;
  profilePhoto: string;
  shape: 'square' | 'circle';
  size: 'md' | 'lg';
}

export function SellerAvatar({ username, profilePhoto, shape, size }: SellerAvatarProps) {
  return (
    <Avatar size={size} shape={shape} username={username} showRing={false}>
      <AvatarImage src={profilePhoto} alt={username} />
    </Avatar>
  );
}
