export interface Rollouts {
    [key: string]: boolean;
}

// //////////////////////////////////
// PLEASE KEEP ALPHABETICAL ORDER
// //////////////////////////////////
export const ROLLOUTS = {
    ANACONDAS_REQUEST_TO_ORDER: 'anacondas_gig_page_request_to_order',
    BULLS_PROMOTED_GIGS_BANNER: 'bulls_promoted_gigs_banner_in_gig_page',
    BULLS_PROMOTED_RECOMMENDED_CAROUSEL: 'bulls_gig_page_promoted_carousel',
    CARIBOU_GIG_REPORT_ELIGIBILITY: 'caribou_gig_report_eligibility',
    CHAM_GIG_PAGE_LAZY_LOAD_PCP: 'cham_gig_page_lazy_load_pcp',
    CHEETAHS_GIG_PAGE_CONSULTATION: 'cheetahs_gig_page_consultation',
    CHEETAHS_GIG_PAGE_DATA_GQL: 'cheetahs_gig_page_data_gql',
    CHEETAHS_GIG_PAGE_DQA: 'cheetahs_gig_page_dqa',
    COBRAS_GIG_RETURNING_BUYER_MODAL: 'cobras_gig_returning_buyer_modal',
    DSA_COMPLIANCE_FOR_EU_CONSUMERS: 'dsa_compliance_for_eu_consumers',
    FOXES_GIG_PAGE_RENDER_DELAY_MESSAGE: 'foxes_gig_page_render_delay_message',
    FOXES_LOG_UNKNOWN_CRAWLERS_IN_GIG_PAGE: 'foxes_log_unknown_crawlers_in_gig_page',
    FOXES_REMOVE_PCP_IN_GIG_PAGE_MOBILE: 'foxes_remove_pcp_in_gig_page_mobile',
    GIG_PAGE_BASE_DATA_ENDPOINT: 'gig_page_base_data_endpoint',
    GIG_PAGE_VOICE_OVER_AI_AUDITION: 'gig_page_voice_over_ai_audition',
    GOATS_GIG_PAGE_EXTERNAL_VISITS: 'goats_gig_page_external_visits',
    HAWKS_CONTINUE_CTA_COPY_TEST: 'hawks_continue_cta_copy_test',
    HAWKS_GIG_PAGE_IS_DSA_APPLIED: 'hawks_gig_page_is_dsa_applied',
    LIONS_DEFAULT_MT_TRANSLATION: 'lions_default_mt_translation',
    LIONS_LOCAL_CATALOG_BUYER_SIDE: 'lions_local_catalog_buyer_side',
    LIONS_LOCAL_REVIEWS_NEW: 'lions_local_reviews_new',
    LIONS_MT_CALL_ON_SSR_CRAWLERS: 'lions_machine_translation_call_on_ssr_crawlers',
    LIONS_MT_CALL_ON_SSR_REAL_USERS_DE: 'lions_machine_translation_call_on_ssr_real_users_de',
    LIONS_MT_CALL_ON_SSR_REAL_USERS_ES: 'lions_machine_translation_call_on_ssr_real_users_es',
    LIONS_MT_CALL_ON_SSR_REAL_USERS_FR: 'lions_machine_translation_call_on_ssr_real_users_fr',
    LIONS_MT_CALL_ON_SSR_REAL_USERS_IT: 'lions_machine_translation_call_on_ssr_real_users_it',
    LIONS_MT_CALL_ON_SSR_REAL_USERS_NL: 'lions_machine_translation_call_on_ssr_real_users_nl',
    LIONS_MT_CALL_ON_SSR_REAL_USERS_PT: 'lions_machine_translation_call_on_ssr_real_users_pt',
    MUSTANGS_LOYALTY_INDICATION_SAFETY: 'mustangs_loyalty_indication_safety',
    PANTHERS_POST_FMP_MORE_GIGS_V4: 'panthers_post_fmp_more_gigs_v_four',
    PIGLETS_GIG_PAGE_PAYPAL_PAYLATER_MESSAGE: 'piglets_gig_page_paypal_paylater_message',
    PRO_LAUNCH_MASTER: 'pro_launch_master',
    QUALAS_GIG_PAGE_WORK_SAMPLES: 'qualas_gig_page_work_samples',
    QUALAS_NEW_LEVEL_SYSTEM_CONSUMERS_MIGRATION: 'qualas_new_level_system_consumers_migration', // BE - New Seller Levels from GQL
    QUALAS_SEARCH_ON_REVIEWS_FEATURE_FLAG: 'qualas_search_on_review_active',
    QUAL_REVIEWS_BUYER_EXPERIENCE: 'qual_reviews_buyer_experience_ii',
    QUAL_SELLER_REVIEWS_FEATURE_FLAG: 'qual_gig_page_seller_reviews_feature_flag',
    STARLINGS_BOOSTING_NEW_GIGS: 'starlings_boosting_new_gigs',
    STARLINGS_FIVERR_GO_FLOATING_CHAT: 'starlings_fiverr_go_floating_chat',
    STARLINGS_FIVERR_GO_INBOX: 'starlings_fiverr_go_inbox',
    VIPERS_CONTACT_DRAWER_ENTRY: 'vipers_contact_drawer_entry',
    ZEBRAS_ACQ_COLLECTION_STEPPER: 'zebras_acq_collection_stepper_gig_page',
    ZEBRAS_CREATE_AI_BRIEF_ON_GIG_PAGE: 'zebras_create_ai_brief_on_gig_page',
    ZEBRAS_HOURLY_RATES: 'zebras_hourly_rate',
    ZEBRAS_MP_HOURLY_FILTER: 'zebras_mp_hourly_filter',
    ZEBRAS_SELLER_HOURLY_ELIGIBILITY: 'zebras_seller_hourly_eligibility',
};
