import React from 'react';
import { Container, Stack } from '@fiverr-private/layout_components';
import { Heading, Text } from '@fiverr-private/typography';
import { I18n } from '@fiverr-private/i18n-react';
import { Seller } from '@fiverr-private/gig_page_context';

interface VettedByProBannerProps extends React.ComponentProps<typeof Stack> {
  seller: Pick<Seller, 'displayName' | 'username'>;
}

export const VettedByProBanner: React.FC<VettedByProBannerProps> = ({ seller, ...props }) => {
  const sellerName = seller.displayName || seller.username;
  return (
    <Stack direction="column" gap="3" alignItems="stretch" justifyContent="spaceBetween" {...props}>
      <Heading as="h6" size="h_xxs" fontWeight="semibold" color="heading">
        <I18n k="gig_page_banners.vetted_by_fiverr.title" />
      </Heading>
      <Container>
        <Text color="bodyPrimary">
          <I18n k="gig_page_banners.vetted_by_fiverr.text" params={{ sellerName }} />
        </Text>
      </Container>
    </Stack>
  );
};
