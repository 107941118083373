import React from 'react';
import { Stack } from '@fiverr-private/layout_components';
import { Heading, Link, Text } from '@fiverr-private/typography';
import { translate } from '@fiverr-private/i18n-react';
import { useGigPageContext } from '@fiverr-private/gig_page_context';
import { getNestedSubCategoryUrl, getSubCategoryUrl } from '../../../utils/routes';
import { reportSeeMoreClick } from '../events/reportSeeMoreClick';

interface CarouselHeaderProps {
  promotedAuctionId?: string;
}

export const CarouselHeader: React.FC<CarouselHeaderProps> = ({ promotedAuctionId }) => {
  const { general, pathfinderWrapper, biEvents } = useGigPageContext();
  const {
    categoryId,
    subCategoryId,
    nestedSubCategoryId,
    categorySlug,
    subCategorySlug,
    nestedSubCategorySlug,
    isPro,
  } = general;

  const showCategory = categoryId && categorySlug;
  const showSubCategory = showCategory && subCategoryId && subCategorySlug;
  const showNestedSubCategory = showSubCategory && nestedSubCategoryId && nestedSubCategorySlug;

  const subCategoryUrl = showSubCategory
    ? getSubCategoryUrl({ categorySlug, subCategorySlug, isPro, pathfinderWrapper, absolute: false })
    : undefined;

  const nestedSubCategoryUrl = showNestedSubCategory
    ? getNestedSubCategoryUrl({
        categorySlug,
        subCategorySlug,
        nestedSubCategorySlug,
        pathfinderWrapper,
        absolute: false,
      })
    : undefined;

  const trackSeeMoreClick = () => {
    reportSeeMoreClick({
      biEvents,
      params: {
        general,
        promotedAuctionId,
      },
    });
  };

  return (
    <Stack style={{ alignItems: 'normal' }} direction="column" gap="1" marginBottom="6">
      <Heading as="h5">{translate('gig_page_perseus.promoted.carousel.header')}</Heading>
      <Stack justifyContent="spaceBetween">
        <Text>{translate('gig_page_perseus.promoted.carousel.sub_header')}</Text>
        <Link
          cursor="pointer"
          href={subCategoryUrl || nestedSubCategoryUrl}
          target="_blank"
          hidden={{ default: true, sm: false }}
          onClick={trackSeeMoreClick}
        >
          {translate('gig_page_perseus.promoted.carousel.link')}
        </Link>
      </Stack>
    </Stack>
  );
};
