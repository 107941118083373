/** NOTE: Keep map ordered by experiment id */
module.exports = {
    // TODO cleanup closed experiments
    COBRAS_GIG_RETURNING_BUYER_MODAL: 3528,
    QUAL_REVIEWS_BUYER_EXPERIENCE: 3808,
    GOATS_GIG_PAGE_EXTERNAL_VISITS: 3860,
    FOXES_REMOVE_PCP_IN_GIG_PAGE_MOBILE: 4114,
    CHAM_GIG_PAGE_LAZY_LOAD_PCP: 4221,
    // // TODO Check Open Experiment Status
    LIONS_LOCAL_REVIEWS_NEW: 4254,
    CHAM_GOATS_POST_FMP_MORE_GIGS_V4: 4282,
    HAWKS_CONTINUE_CTA_COPY: 4366,
    ZEBRAS_CREATE_AI_BRIEF_ON_GIG_PAGE_EXPERIMENT: 4569,
    ZEBRAS_HOURLY_INDICATIONS_EXPERIMENT: 4652,
    ZEBRAS_MP_HOURLY_FILTER: 4736,
};
