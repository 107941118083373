import React, { useMemo } from 'react';
import { CheckIcon } from '@fiverr-private/visuals';
import { translate } from '@fiverr-private/i18n-react';
import { Center, Grid, GridItem, Stack } from '@fiverr-private/layout_components';
import { Text } from '@fiverr-private/typography';
import type { Types } from '@fiverr-private/theme';
import { ProSubCategory } from '../../types/seller';
import { buildSubCategoryNameKey } from '../../utils/translation/categories';
import { FoldButton } from './FoldButton/FoldButton';
import { useFoldable } from './hooks/useFoldable';

const DEFAULT_FOLD_LIMIT = 6;

export type ColumnCount = 2 | 3;
interface ExpertListProps {
  proSubcategories: ProSubCategory[];
  columnCount?: ColumnCount;
  isFoldable?: boolean;
  foldLimit?: number;
}

export const ExpertList: React.FC<ExpertListProps> = ({
  proSubcategories = [],
  columnCount = 2,
  isFoldable = false,
  foldLimit = DEFAULT_FOLD_LIMIT,
}) => {
  const sortedSubcategoriesNames = useMemo(
    () => proSubcategories.map(({ id }) => translate(buildSubCategoryNameKey(id))).sort(),
    [proSubcategories]
  );
  const itemsCount = sortedSubcategoriesNames.length;

  const { supportsFolding, isFolded, setIsFolded } = useFoldable({
    isFoldable,
    foldLimit,
    itemsCount,
  });

  if (proSubcategories.length === 0) {
    return null;
  }

  const titleMarginBottom = isFoldable ? '2' : '4';
  const listRowGap = isFoldable ? '3' : '4';

  const getGridTemplate = (): Types.ResponsiveGridTemplatesType | undefined => {
    if (itemsCount === 1) {
      return '100%';
    }
    if (itemsCount === 2) {
      if (columnCount === 2) return { default: '100%', sm: '50%_50%' };
      if (columnCount === 3) return { default: '100%', md: '50%_50%' };
    }
    if (itemsCount > 2) {
      if (columnCount === 2) return { default: '100%', sm: '50%_50%' };
      if (columnCount === 3) return { default: '100%', md: '50%_50%', lg: '33%_33%_33%' };
    }

    return undefined;
  };

  const expertListItems = isFolded ? sortedSubcategoriesNames.slice(0, foldLimit) : sortedSubcategoriesNames;

  return (
    <>
      <Text marginBottom={titleMarginBottom} fontWeight="semibold">
        {translate('gig_page_perseus.expert_list.title')}
      </Text>
      <Grid templateColumns={getGridTemplate()} as="ul" columnGap="2" rowGap={listRowGap}>
        {expertListItems.map((subCategory) => (
          <GridItem key={`subcategory-${subCategory}`} as="li">
            <Stack alignItems="baseline" gap="2">
              <Center alignSelf="flexStart" height={24} width="fit-content">
                <CheckIcon size="sm" />
              </Center>
              <Text>{subCategory}</Text>
            </Stack>
          </GridItem>
        ))}
      </Grid>
      {supportsFolding && <FoldButton isFolded={isFolded} onClick={setIsFolded} />}
    </>
  );
};
