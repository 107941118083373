import React from 'react';
import { bool, array } from 'prop-types';
import { connect } from 'react-redux';
import { I18n } from '@fiverr-private/i18n-react';
import { Text } from '@fiverr-private/typography';
import { useGigPageContext } from '@fiverr-private/gig_page_context';
import RecommendedBadge from '../../shared/RecommendedBadge';
import TranslatedBadge from '../../shared/TranslatedBadge';
import { getPackageTypeById } from '../../../utils/packagesUtils';
import { isTranslated } from '../../../utils/ugc/isTranslated';
import { isFieldTranslatedBySeller } from '../../../utils/ugc/isFieldTranslatedBySeller';
import './style.scss';

const PackageType = ({ packageList, showTranslatedBadge = false }) => {
    const { currencyConverter, shouldDisplayFees } = useGigPageContext();

    return (
        <tr className="package-type">
            <th className="package-row-label">
                <I18n k="gig_page_perseus.packages_table.package" />
                {showTranslatedBadge && <TranslatedBadge />}
            </th>
            {packageList.map((packageData) => {
                const { currentPrice, id, recommendedType } = packageData;
                const convertedPrice = currencyConverter.convertWithFee(currentPrice);

                return (
                    <th key={id} className="package-type-price">
                        {recommendedType && (
                            <RecommendedBadge
                                recommendedType={recommendedType}
                                tooltipPosition={RecommendedBadge.POSITIONS.BOTTOM}
                            />
                        )}
                        <div className="price-wrapper">
                            <p className="price">{convertedPrice}</p>
                            {shouldDisplayFees && <Text paddingLeft="1.5" size="b_lg" color="inherit" as="span">
                                <I18n k="gig_page_perseus.packages.plus_fees" />
                            </Text>}
                        </div>
                        <b className="type">
                            <I18n k={getPackageTypeById(id)} />
                        </b>
                        <b className="title">{packageData.title}</b>
                    </th>
                );
            })}
        </tr>
    );
}

const mapStateToProps = ({ packages, ugc }) => {
    const isFieldFromSellerTranslations = isFieldTranslatedBySeller(ugc, 'packages');

    return {
        packageList: packages.packageList,
        showTranslatedBadge: isTranslated(ugc) && !isFieldFromSellerTranslations,
    };
};

PackageType.propTypes = {
    packageList: array,
    showTranslatedBadge: bool,
};

export { PackageType };
export default connect(mapStateToProps)(PackageType);
