const CATEGORIES = {
    GRAPHICS_AND_DESIGN: 3,
    WRITING_AND_TRANSLATION: 5,
    PROGRAMMING_TECH: 10,
};

const SUB_CATEGORIES = {
    MIXING_MASTERING: 10,
    VOICE_OVERS: 16,
    VIRTUAL_ASSISTANT: 25,
    FINANCIAL_CONSULTING: 28,
    LEGAL_CONSULTING: 30,
    SEARCH_ENGINE_OPTIMIZATION: 65,
    SOCIAL_MARKETING: 67,
    VIDEO_MARKETING: 69,
    ONLINE_TUTORING: 77,
    ASTROLOGY_AND_PSYCHICS: 78,
    SPIRITUAL_AND_HEALING: 79,
    VIDEO_EDITING: 99,
    BUY_VIDEO_TESTIMONIALS: 101,
    RESUMES_COVER_LETTERS: 112,
    PROOFREADING: 114,
    SOCIAL_MEDIA_DESIGN: 149,
    WEB_MOBILE_DESIGN: 151,
    ARTICLES_BLOG_POSTS: 163,
    RESEARCH_AND_SUMMARIES: 169,
    SEARCH_ENGINE_MARKETING: 200,
    LOCAL_SEO: 206,
    WHITEBOARD_EXPLAINER_VIDEOS: 228,
    ECOMMERCE_MARKETING: 325,
    MUSIC_PROMOTION: 327,
    PODCAST_EDITING: 390,
    PODCAST_MARKETING: 393,
    SOCIAL_MEDIA_COPY: 395,
    SOCIAL_MEDIA_ADVERTISING: 407,
    UNBOXING_VIDEOS: 427,
    CRYPTOCURRENCY: 464,
    NFT_ART: 480,
    SOCIAL_MEDIA_VIDEOS: 482,
    NFT_DEVELOPMENT: 483,
    NFT_ANIMATION: 484,
    WEBSITE_DEVELOPMENT: 514,
    WEBSITE_MAINTENANCE: 515,
    UGC: 549,
    ONLINE_INVESTIGATIONS: 598,
    AI_AVATAR_DESIGN: 601,
    AI_IMAGE_EDITING: 613,
};

const NESTED_SUB_CATEGORIES = {
    SEO_ON_PAGE_SEO: 2153,
    SEO_TECHNICAL_SEO: 2154,
    SEO_OFF_PAGE_SEO: 2155,
    SEO_VOICE_SEARCH_SEO: 2300,
    CALL_CENTER_AND_CALLING: 2347,
    SEO_FULL_SEO_PACKAGE: 2394,
    AI_VOICE_SERVICE: 2701,
    BLOCKCHAIN_SECURITY_AUDITING: 2888,
    VTUBER_MODELS: 2902,
    VR_AND_METAVERSE_AVATARS: 2903,
    AI_AVATARS: 2904,
    FRACTIONAL_CFO_SERVICES: 2919,
    FINANCIAL_REPORTING: 2920,
    BOOKKEEPING: 2921,
    PAYROLL_MANAGEMENT: 2922,
    TAX_RETURNS: 2923,
    TAX_IDENTIFICATION_SERVICES: 2924,
    TAX_PLANNING: 2925,
    TAX_COMPLIANCE: 2926,
    TAX_EXEMPTIONS: 2927,
    DUE_DILIGENCE: 2912,
    MERGERS_AND_ACQUISITIONS: 2914,
    INVESTMENTS_ADVISORY: 2929,
    BUSINESS_STRUCTURE_CONSULTATION: 2939,
    BUSINESS_NAME_RESEARCH: 2940,
    DOCUMENT_PREPARATION: 2941,
    TAX_SETUP_AND_EIN_APPLICATION: 2942,
    BUSINESS_REGISTRATION: 2943,
    COMPLIANCE_AND_ONGOING_REQUIREMENTS: 2944,
    PATENT_AND_TRADEMARK_SEARCH: 2945,
    FILING_AND_REGISTRATION: 2946,
    PATENT_LICENSING_AND_MONETIZATION: 2947,
    TRADEMARK_MONITORING_AND_ENFORCEMENT: 2948,
    INFRINGEMENT_PROTECTION_AND_DMCA_TAKEDOWN: 2949,
    INTELLECTUAL_PROPERTY_CONSULTING: 2950,
};

module.exports = {
    CATEGORIES,
    SUB_CATEGORIES,
    NESTED_SUB_CATEGORIES,
};
