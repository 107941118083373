import React from 'react';
import { isEmpty, isEqual } from 'lodash';
import classNames from 'classnames';
import { getContext } from '@fiverr-private/fiverr_context';
import { useGigPageContext } from '@fiverr-private/gig_page_context';
import { I18n, translate } from '@fiverr-private/i18n-react';
import { Container } from '@fiverr-private/layout_components';
import { CONSULTATION_DEFAULT_RESPONSE } from '@fiverr-private/consultation';
import { useExperiments } from '../../hooks/useExperiments';
import { useRecentInteractions } from '../../hooks/useRecentInteractions';
import { shouldRenderCustomOrder } from '../../common/customOrder';
import { CustomOrder } from '../../types/seller';
import { BQ_SOURCE_NAME, CONTACT_OPTIONS_TYPE, MP_SOURCE_COMPONENT } from './constants';
import { getContactOptionsType } from './utils';
import ContactSellerOptions from './ContactSellerOptions';
import ContactSellerBtn from './ContactSellerBtn';
import CustomOrderRequestBtn from './CustomOrderRequestBtn';
import { useContactButtonImpression } from './useContactButtonImpression';
import { hourlyContactSellerButtonContainer } from './ContactSellerOptions/ContactButtonStyles/TabContent/TabContent.ve.css';

import './style.scss';

export interface ContactSellerProps {
  customOrder: CustomOrder;
  className?: string;
}

export const ContactSeller: React.FC<ContactSellerProps> = ({ customOrder, className }) => {
  const { userId } = getContext();
  const { general, seller, logger, consultationData, isFloatingChatEnabled } = useGigPageContext();
  const { inAIBriefTest } = useExperiments();

  const isConsultationButtonDisabled = !consultationData || isEqual(consultationData, CONSULTATION_DEFAULT_RESPONSE);

  const { isAgency } = seller;
  const contactSellerTitle = isAgency
    ? `gig_page_perseus.contact_seller.title_agency`
    : `gig_page_perseus.contact_seller.title`;

  const customOrderEligible = shouldRenderCustomOrder({
    userId,
    customOrder,
    general,
  });

  const consultationEligible = !isEmpty(consultationData);

  const contactOptionsType = getContactOptionsType(
    customOrderEligible,
    consultationEligible,
    inAIBriefTest,
    isFloatingChatEnabled,
    isConsultationButtonDisabled
  );

  const { checkRecentInteractions } = useRecentInteractions();

  const observableRef = useContactButtonImpression({ elementType: contactOptionsType, checkRecentInteractions });

  let component;

  switch (contactOptionsType) {
    case CONTACT_OPTIONS_TYPE.DROPDOWN:
      component = <ContactSellerOptions customOrderEligible={customOrderEligible} />;
      break;
    case CONTACT_OPTIONS_TYPE.GET_A_QUOTE_ONLY:
      component = <CustomOrderRequestBtn />;
      break;
    case CONTACT_OPTIONS_TYPE.CONTACT_SELLER_ONLY:
      const contactSellerTitleTranslation = translate(contactSellerTitle);

      component = (
        <ContactSellerBtn
          mixpanelSourceComponent={MP_SOURCE_COMPONENT}
          bqSourceName={BQ_SOURCE_NAME}
          triggerId="Contact Seller"
          triggerCopy={contactSellerTitleTranslation}
          triggerPlacement="CTA right"
        >
          <I18n k={contactSellerTitle} />
        </ContactSellerBtn>
      );
      break;
    case CONTACT_OPTIONS_TYPE.NONE:
      component = null;
      break;
    default:
      logger.error(new Error('Floating contact seller experiment error: No contact seller options matched'));
      component = null;
  }

  if (!component) {
    return null;
  }

  return (
    <Container
      className={classNames('contact-seller', className, hourlyContactSellerButtonContainer)}
      style={{ backgroundColor: 'inherit', borderRadius: 'inherit', paddingBottom: 0 }}
      ref={observableRef}
    >
      {component}
    </Container>
  );
};
