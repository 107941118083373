import React from 'react';
import { getContext } from '@fiverr-private/fiverr_context';
import { useGigPageContext } from '@fiverr-private/gig_page_context';
import { FiverrChoiceBadge, VettedProBadge } from '@fiverr-private/badges';
import { isFiverrChoice } from '../../../utils/fiverrChoice';

export const Badges = () => {
  const { queryParameters } = getContext();
  const {
    general: { isPro },
    choiceEligibilities,
    isBusinessLayout,
    isProRebrandingLayout,
  } = useGigPageContext();

  const showFiverrChoiceBadge = isFiverrChoice({ queryParameters, choiceEligibilities });
  const showProBadge = isPro && !showFiverrChoiceBadge && (isBusinessLayout || isProRebrandingLayout);

  if (!showFiverrChoiceBadge && !showProBadge) {
    return null;
  }

  return (
    <>
      {showFiverrChoiceBadge && <FiverrChoiceBadge />}
      {showProBadge && <VettedProBadge tooltipPosition="bottom" dataTestId="pro-badge" />}
    </>
  );
};
