import React from 'react';
import { Button, ModelGradient, BiEventData, ReportBiEventParams, reportBiEvent } from '@fiverr-private/go_shared_ui';
import { ArrowRightIcon } from '@fiverr-private/visuals';
import { Container, Stack } from '@fiverr-private/layout_components';
import pathfinder from '@fiverr-private/pathfinder';
import { Typography, Text } from '@fiverr-private/typography';
import { Textarea } from '@fiverr-private/forms';
import { translate } from '@fiverr-private/i18n-react';
import { BIANKA_ACTIVITY_TYPES } from '@fiverr-private/obs';
import { ElementType, ClickType } from '@fiverr-private/bianka_js';
import { getContext } from '@fiverr-private/fiverr_context';
import { useGigPageContext, SellerAIModel } from '@fiverr-private/gig_page_context';
import * as style from './AiPackageContent.ve.css';

interface AiPackageContentProps {
  aiModelId: string;
  aiModelName: string;
  theme: SellerAIModel['theme'];
  sellerName: string;
}
export const AiPackageContent: React.FC<AiPackageContentProps> = ({ aiModelId, aiModelName, theme, sellerName }) => {
  const [prompt, setPrompt] = React.useState('');
  const {
    general: { sellerId },
  } = useGigPageContext();

  const title = translate('gig_page_perseus.packages.ai_model.title');
  const description = translate('gig_page_perseus.packages.ai_model.description');
  const cta = translate('gig_page_perseus.packages.ai_model.cta');
  const promptPlaceholder = translate('gig_page_perseus.packages.ai_model.placeholder');
  const bannerText = translate('gig_page_perseus.packages.ai_model.banner', {
    params: { modelName: aiModelName },
  });

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const { userId, pageCtxId } = getContext();

    const biEventParams: BiEventData = {
      ...(userId && { userId }),
      ...(sellerId && { sellerId }),
      ctxId: pageCtxId,
      pageName: 'gig_page',
    };

    const reportBiEventParams: ReportBiEventParams = {
      biEventData: biEventParams,
      activityType: BIANKA_ACTIVITY_TYPES.CLICK,
      eventType: 'clicked_on_generate_in_drawer',
      elementName: 'generate',
      elementType: ElementType.BUTTON,
      clickType: ClickType.UNSPECIFIED,
    };

    const buttonHref = pathfinder(
      'ai_hub_playground_create_tab_new_model_conversation',
      {
        seller_username: sellerName,
        model_slug: aiModelId,
      },
      { query: { prompt, source: 'gig_page_package' } }
    );

    reportBiEvent(reportBiEventParams);
    window.location.href = buttonHref;
  };

  return (
    <Stack
      className="reveal-vertical-content"
      direction="column"
      padding="8"
      gap="3"
      borderBottomLeftRadius="xl"
      borderBottomRightRadius="xl"
    >
      <Stack height="112px" borderRadius="xl" justifyContent="center" alignItems="center" position="relative">
        <ModelGradient animate="none" {...theme} />
        <Container
          className={style.bannerTextContainer}
          paddingX="3"
          paddingY="1"
          width="max-content"
          maxWidth="100%"
          position="absolute"
          borderRadius="circle"
          top="50%"
          left="50%"
          background="rgba(0, 0, 0, 0.2)"
        >
          <Text className={style.powerGroteskFont} color="white" fontWeight="bold" size="b_md" textAlign="center">
            {bannerText}
          </Text>
        </Container>
      </Stack>
      <Stack direction="column" marginTop="3" gap="1">
        <Typography color="white" fontWeight="bold" size="b_lg">
          {title}
        </Typography>
        <Typography color="white" fontWeight="normal" size="b_sm">
          {description}
        </Typography>
      </Stack>

      <form onSubmit={handleSubmit}>
        <Textarea
          className={style.promptTextarea}
          name="prompt"
          placeholder={promptPlaceholder}
          onChange={(e) => setPrompt(e.target.value)}
        />
        <Button type="submit" marginTop="6" fullWidth>
          {cta}
          <ArrowRightIcon />
        </Button>
      </form>
    </Stack>
  );
};
