import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { I18n } from '@fiverr-private/i18n-react';
import { HourlyIcon } from '@fiverr-private/visuals';
import { Card, Wrap, Stack, Container } from '@fiverr-private/layout_components';
import { Text } from '@fiverr-private/typography';
import { Button } from '@fiverr-private/button';
import { useGigPageContext } from '@fiverr-private/gig_page_context';
import { Seller } from '../../types/seller';
import { updateShowFirstMessagePopup } from '../../actions/widgets';
import { reportClickEvent, reportImpressionEvent } from './bannerEvents';
import { useGetSellerEligibility } from './useGetSellerEligibility';

interface OffersHourlyRatesBannerProps {
  seller: Seller;
  updateShowFirstMessagePopup(show: boolean): void;
}

const OffersHourlyRatesBannerView: React.FC<OffersHourlyRatesBannerProps> = ({
  seller,
  updateShowFirstMessagePopup,
}) => {
  const { biEvents } = useGigPageContext();
  const showHourlyRate = useGetSellerEligibility(seller);

  useEffect(() => {
    if (showHourlyRate) {
      reportImpressionEvent(biEvents);
    }
  }, [showHourlyRate, biEvents]);

  if (!showHourlyRate) {
    return null;
  }

  const onClick = () => {
    updateShowFirstMessagePopup(true);
    reportClickEvent(biEvents);
  };

  return (
    <Card display="block" hidden={{ default: true, md: false }} borderRadius="lg" marginTop="3">
      <Stack justifyContent="spaceBetween" alignItems="center" direction="row" gap="3" paddingX="6" paddingY="4">
        <Stack>
          <Wrap flex="0" paddingTop="0.5">
            <HourlyIcon size="sm" color="grey_1200" />
          </Wrap>
          <Wrap flex="1" gap="1" direction="column">
            <Text fontWeight="semibold" size="b_sm">
              <I18n k="gig_page_perseus.offers_hourly_rates.banner.title" />
            </Text>
            <Text size="b_sm">
              <I18n k="gig_page_perseus.offers_hourly_rates.banner.description" />
            </Text>
          </Wrap>
        </Stack>
        <Container>
          <Button size="sm" variant="outline" onClick={onClick} style={{ width: 'max-content' }}>
            <I18n k="gig_page_perseus.offers_hourly_rates.banner.ask_cta" />
          </Button>
        </Container>
      </Stack>
    </Card>
  );
};

const mapDispatchToProps = {
  updateShowFirstMessagePopup,
};

type DispatchProps = keyof typeof mapDispatchToProps;

export const OffersHourlyRatesBanner: React.FC<
  Omit<React.ComponentProps<typeof OffersHourlyRatesBannerView>, DispatchProps>
> = connect(null, mapDispatchToProps)(OffersHourlyRatesBannerView);
