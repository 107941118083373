import { createContext } from 'react';
import { noop } from 'lodash';
import pathfinder from '@fiverr-private/pathfinder';
import { CONSULTATION_DEFAULT_RESPONSE } from '@fiverr-private/consultation';
import { General, GigPageContextType } from '../../interfaces';
import { CurrencyConverter } from '../../interfaces/currencyConverter';
import { SellerCard } from '../../interfaces/gigPageContext';

export const GigPageContext = createContext<GigPageContextType>({
    biEvents: {
        sendBigQueryEvent: noop,
        sendRawBigQueryEvent: noop,
        sendMixPanelEvent: noop,
        getBigQueryEnrichmentData: () => ({
            userId: -1,
            userGuid: '',
            gigId: -1,
            sellerId: -1,
            pageCtxId: '',
            pageName: '',
            platform: '',
        }),
    },
    choiceEligibilities: [],
    currencyConverter: {} as CurrencyConverter,
    general: {} as General,
    gig: {
        tags: {
            tagsGigList: [],
        },
        instantOrderSettings: {
            audience: '',
            packages: [],
            unavailabilityHours: [],
        },
    },
    gigTheme: 'experiential',
    isBusinessLayout: false,
    isProRebrandingLayout: false,
    logger: {
        error: noop,
        warn: noop,
    },
    pathfinderWrapper: {
        pathfinder,
        errorCallback: noop,
    },
    rollouts: {},
    seller: {
        isAgency: false,
        achievement: 0,
        responseTime: 0,
    },
    sellerLanguageParams: {
        sellerLanguages: [],
        sellerCountryCode: 'US',
    },
    isPageOwner: false,
    sellerUsername: '',
    sectionScroller: {
        currentSection: 'overview',
        onEnterWaypoint: noop,
        onLeaveWaypoint: noop,
        refsObj: {},
        scroll: noop,
    },
    currentUser: {
        userId: -1,
        username: '',
        status: '',
        isReturningBuyer: true,
    },
    consultationData: CONSULTATION_DEFAULT_RESPONSE,
    loyaltyIndication: undefined,
    shouldDisplayFees: false,
    aiModels: [],
    isFloatingChatEnabled: false,
    isRestrictedPage: false,
    sellerCard: {} as SellerCard,
});
