import { SET_CUSTOM_ORDER } from '../../actions/setCustomOrder';

const customOrderReducer = (customOrder) => {
    const initialState = customOrder || {
        allowCustomOrders: false,
        seller: {},
        gig: {},
    };

    return (state = initialState, action) => {
        const { type, data } = action;

        switch (type) {
            case SET_CUSTOM_ORDER:
                return data;

            default:
                return state;
        }
    };
};

export default customOrderReducer;
