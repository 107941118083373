import React from 'react';
import classNames from 'classnames';
import { Text } from '@fiverr-private/typography';
import { I18n } from '@fiverr-private/i18n-react';
import { useGigPageContext } from '@fiverr-private/gig_page_context';
import { Container } from '@fiverr-private/layout_components';
import { SELLER_COUPON_TYPES } from '../../../GigPage/constants';
import { useShowPromotionDiscountedPriceBIEvent } from '../hooks/useShowPromotionDiscountedPriceBIEvent';
import { HeaderProps } from '../HeaderDefault/types';
import PriceTooltipIcon from '../PriceTooltipIcon';
import SubscriptionDrawerLabel from './SubscriptionDrawerLabel';
import SubscriptionPackageDescription from './SubscriptionPackageDescription';
import './style.scss';

const HeaderRecurring: React.FC<HeaderProps> = ({
  title,
  showPrice,
  formattedPrice,
  description,
  showPriceTooltip,
  discountedPriceSection,
}) => {
  const { coupon, shouldDisplayFees } = useGigPageContext();

  useShowPromotionDiscountedPriceBIEvent(!!discountedPriceSection);

  {
    /* When the promotion is present, the SubscriptionDrawerLabel component
        is moved under the "Continue" CTA. */
  }
  const isSubscriptionLabelHidden = coupon && coupon.type === SELLER_COUPON_TYPES.PROMOTION;

  return (
    <header className="header-recurring">
      <h3>
        {showPrice && (
          <div className="price-wrapper">
            <span className={classNames({ 'price-with-tooltip': !!showPriceTooltip })}>
              <Container>
                <span className="price">{formattedPrice}</span>
                {shouldDisplayFees && (
                  <Text
                    paddingLeft="1.5"
                    style={{ fontSize: '24px', display: 'inline-block' }}
                    color="bodySecondary"
                    as="span"
                  >
                    <I18n k="gig_page_perseus.packages.plus_fees" />
                  </Text>
                )}
              </Container>
              {showPriceTooltip && <PriceTooltipIcon />}
            </span>
            {!isSubscriptionLabelHidden && <SubscriptionDrawerLabel />}
          </div>
        )}
      </h3>
      {discountedPriceSection && <div className="m-t-4">{discountedPriceSection}</div>}
      <SubscriptionPackageDescription title={title} description={description} />
    </header>
  );
};

export default HeaderRecurring;
