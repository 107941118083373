import React from 'react';
import { noop } from 'lodash';
import { I18n } from '@fiverr-private/i18n-react';
import { Button } from '@fiverr-private/button';
import { Text } from '@fiverr-private/typography';
import { useGigPageContext } from '@fiverr-private/gig_page_context';
import { CheckoutCtaProps } from './types';

const CheckoutCta: React.FC<CheckoutCtaProps> = ({ className, price, onClick = noop }) => {
  const { shouldDisplayFees } = useGigPageContext();

  return (
    <Button className={className} onClick={onClick} type="submit">
      <Text fontWeight="inherit" color="inherit" as="span">
        <I18n k="gig_page_perseus.packages.continue" />
      </Text>
      <Text
        fontWeight="inherit"
        color="inherit"
        as="span"
        overflow="hidden"
        textOverflow="ellipsis"
        whiteSpace="nowrap"
      >
        ({price}
        {shouldDisplayFees && (
          <Text fontWeight="inherit" color="inherit" paddingLeft="1.5" as="span">
            <I18n k="gig_page_perseus.packages.plus_fees" />
          </Text>
        )}
        )
      </Text>
    </Button>
  );
};

export default CheckoutCta;
