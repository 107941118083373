import React, { Component } from 'react';
import { connect } from 'react-redux';
import { arrayOf, bool, number, object, oneOf, shape, string } from 'prop-types';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import { getContext } from '@fiverr-private/fiverr_context';
import { Heading } from '@fiverr-private/typography';
import { Stack } from '@fiverr-private/layout_components';
import {
    BQ_CLICKED_REVIEWS_RATINGS,
    BQ_GIG_TITLE_IMPRESSION,
    BQ_NOTABLE_CLIENT_GIG_ELIGIBLE,
} from '../../utils/bigQueryEvents';
import { validateOverview } from '../../utils/mainComponentsValidator';
import { OVERVIEW, REVIEWS } from '../../utils/pageSections';
import { getCurrentContent } from '../../utils/ugc/getCurrentContent';
import { shouldRenderNotableClients } from '../shared/NotableClients/helper';
import ImpressionObserver from '../../utils/ImpressionObserver';
import { isNonExperientialGigTheme } from '../../utils/theme/isNonExperientialGigTheme';
import SafePureComponent from '../shared/SafePureComponent';
import { LOCAL_CATALOG_PREVIEW_STATES } from '../GigPage/constants';
import SellerTranslationBadgeWrapper from '../shared/SellerTranslationBadgeWrapper';
import { Waypoint } from '../Waypoint/Waypoint';
import LoyaltySellerBanner from './LoyaltySellerBanner';
import NotableClientsBar from './NotableClientsBar';
import TranslationLayerPreviewBanner from './TranslationLayerPreviewBanner';
import { SellerProfileWrapper } from './SellerProfile/SellerProfileWrapper';
import { RestrictedBanner } from './RestrictedBanner/RestrictedBanner';

import './style.scss';

class GigOverview extends Component {
    constructor(props) {
        super(props);

        this.onReviewsClick = this.onReviewsClick.bind(this);
        this.setGigTitleRef = this.setGigTitleRef.bind(this);
    }

    componentDidMount() {
        const { notableClients } = this.props;
        const { biEvents, general } = this.context;
        const { gigId } = general;

        if (notableClients.length) {
            biEvents.sendBigQueryEvent({
                eventName: BQ_NOTABLE_CLIENT_GIG_ELIGIBLE,
                params: {
                    clients: notableClients.map((client) => client.name),
                },
            });
        }

        const gigTitleImpressionCallback = () => {
            biEvents.sendBigQueryImpression({
                eventName: BQ_GIG_TITLE_IMPRESSION,
                params: {
                    gig: {
                        id: gigId,
                    },
                },
            });
        };

        this.impressionObserver = new ImpressionObserver({
            element: this.titleElement,
            callback: gigTitleImpressionCallback,
        });
    }

    componentWillUnmount() {
        this.impressionObserver.release();
    }

    setGigTitleRef(element) {
        this.titleElement = element;
    }

    onReviewsClick() {
        const { biEvents, sectionScroller } = this.context;

        biEvents.sendBigQueryEvent({ eventName: BQ_CLICKED_REVIEWS_RATINGS });
        sectionScroller.scroll({ sectionKey: REVIEWS, forceScroll: true });
    }

    showLoyaltySellerBanner() {
        const { repeatScore } = this.props;
        const { gigTheme } = this.context;
        const { isMobile } = getContext();

        return !isNonExperientialGigTheme(gigTheme) && !isEmpty(repeatScore) && !isMobile;
    }

    render() {
        const { overview, useStickyLayout, studioInfo, gigTitle, notableClients, isRestrictedPage } =
            this.props;

        if (!validateOverview({ overview })) {
            return null;
        }

        const { sellerLanguageParams, gigTheme } = this.context;
        const { seller } = overview;
        const { queryParameters } = getContext();
        const { preview_state } = queryParameters;
        const showLoyaltySellerBanner = this.showLoyaltySellerBanner();
        const showNotableClients = shouldRenderNotableClients(notableClients);
        const showLoyaltyAndClientBar = showLoyaltySellerBanner || showNotableClients;
        const showTranslationLayerPreviewBanner = [
            LOCAL_CATALOG_PREVIEW_STATES.BEFORE_PUBLISH,
            LOCAL_CATALOG_PREVIEW_STATES.READY_TRANSLATION_LAYER,
        ].includes(preview_state);
        const isNonExperientialGig = isNonExperientialGigTheme(gigTheme);
        const gigOverviewClasses = classNames(
            'gig-overview',
            { 'with-seller-language': sellerLanguageParams?.shouldShowSellerLanguage },
            { 'with-loyalty': showLoyaltySellerBanner }
        );
        const loyaltyAndClientBarClasses = classNames('loyalty-and-notable-clients m-t-16 p-t-20 m-b-16', {
            'notable-clients-rendered': showNotableClients,
        });

        return (
            <>
                <Waypoint source={OVERVIEW} />
                <div className={gigOverviewClasses}>
                    {showTranslationLayerPreviewBanner && <TranslationLayerPreviewBanner />}
                    <Heading
                        as="h1"
                        size={{ default: 'h_md', sm: 'h_lg' }}
                        style={{
                            wordBreak: 'break-word',
                            overflowWrap: 'break-word',
                            whiteSpace: 'normal',
                        }}
                        paddingBottom={isNonExperientialGig ? undefined : '4'}
                        ref={this.setGigTitleRef}
                    >
                        <SellerTranslationBadgeWrapper translationKey="gigTitle">
                            {gigTitle}
                        </SellerTranslationBadgeWrapper>
                    </Heading>
                    <div className="seller-overview">
                        <SellerProfileWrapper
                            overview={overview}
                            studioInfo={studioInfo}
                            useStickyLayout={useStickyLayout}
                        />
                    </div>
                    {showLoyaltyAndClientBar && (
                        <div className={loyaltyAndClientBarClasses}>
                            {showLoyaltySellerBanner && (
                                <LoyaltySellerBanner
                                    sellerName={seller.username}
                                    wrap={showNotableClients}
                                    displayAltTitle={showNotableClients}
                                />
                            )}
                            {showNotableClients && (
                                <NotableClientsBar
                                    notableClients={notableClients}
                                    sellerName={seller.username}
                                    profilePhoto={overview.seller?.profilePhoto}
                                    wrap={showLoyaltySellerBanner}
                                />
                            )}
                        </div>
                    )}
                    {isRestrictedPage && (
                        <Stack marginBottom="2.5">
                            <RestrictedBanner />
                        </Stack>
                    )}
                </div>
            </>
        );
    }
}

GigOverview.propTypes = {
    overview: shape({
        gig: shape({
            title: string.isRequired,
            rating: number,
            ratingsCount: number,
            ordersInQueue: number,
        }),
        seller: shape({
            id: number.isRequired,
            username: string,
            achievement: number,
            isPro: bool,
            hasProfilePhoto: bool,
            profilePhoto: string,
        }),
    }),
    studioInfo: object,
    useStickyLayout: bool,
    gigTitle: string,
    repeatScore: shape({
        score: bool,
    }),
    notableClients: arrayOf(object),
    isRestrictedPage: bool,
};

GigOverview.defaultProps = {
    studioInfo: {},
    notableClients: [],
    isRestrictedPage: false,
};

GigOverview.contextTypes = {
    biEvents: object,
    general: object,
    sellerLanguageParams: object,
    gigTheme: oneOf(['experiential', 'nonExperiential']),
    sectionScroller: object,
};

const mapStateToProps = ({ ugc }) => {
    const { gigTitle } = getCurrentContent({ ugc });

    return {
        gigTitle,
    };
};

const ConnectedOverView = connect(mapStateToProps)(GigOverview);

export { GigOverview, ConnectedOverView };

export default SafePureComponent(ConnectedOverView);
