import React from 'react';
import { Stack } from '@fiverr-private/layout_components';
import { Alert, AlertText } from '@fiverr-private/feedback';
import { Link, Typography } from '@fiverr-private/typography';
import { I18n } from '@fiverr-private/i18n-react';
import pathfinder from '@fiverr-private/pathfinder';

export const RestrictedBanner: React.FC<void> = () => (
  <Stack marginTop="5">
    <Alert variant="error">
      <AlertText>
        <Typography textAlign="center">
          <I18n k={'gig_page_perseus.general.restrictedPageBanner'} />
          <Link
            href={pathfinder('billing_page_tab', { tab: 'billing-info' })}
            target="_blank"
            rel="noreferrer noopener"
          >
            <I18n k={'gig_page_perseus.general.billingInfo'} />
          </Link>
        </Typography>
      </AlertText>
    </Alert>
  </Stack>
);
