import { useState } from 'react';
import { getContext } from '@fiverr-private/fiverr_context';
import { useGigPageContext } from '@fiverr-private/gig_page_context';
import { fetchInitialData } from '@fiverr-private/send_message';

export const useRecentInteractions = () => {
    const { logger } = useGigPageContext();
    const { pathParameters, userId } = getContext();
    const { username: sellerUsername } = pathParameters;

    const [isInitialized, setIsInitialized] = useState(false);
    const [hadRecentInteractions, setHadRecentInteractions] = useState(false);

    const checkRecentInteractions = async () => {
        try {
            if (isInitialized) return hadRecentInteractions;

            if (userId && sellerUsername) {
                const contactSellerData = await fetchInitialData(sellerUsername);
                const priorInteraction = !!contactSellerData?.priorInteraction;
                setHadRecentInteractions(priorInteraction);
                setIsInitialized(true);
                return priorInteraction;
            }
        } catch (e) {
            if (e instanceof Error) {
                logger.warn(e.name, {
                    description: 'Error while triggering contact seller popover',
                });
            }
        }
        return false;
    };

    return { checkRecentInteractions };
};
