import React from 'react';
import classNames from 'classnames';
import { Button, ButtonProps } from '@fiverr-private/button';
import { Container } from '@fiverr-private/layout_components';
import {
  tabButtonBorderStyle,
  tabContactButtonContainerStyle,
} from '../../ContactSeller/ContactSellerOptions/ContactButtonStyles/TabContent/TabContent.ve.css';
import * as classes from './GradientBorderButton.ve.css';
import './styles.scss';

export const GradientBorderButton = ({
  withBorder = false,
  children,
  ...props
}: ButtonProps & { withBorder?: boolean; children: React.ReactNode }) => (
  <Container
    className={classNames({ [classes.gradientBorderStyle]: withBorder }, tabContactButtonContainerStyle)}
    position="relative"
  >
    <Button {...props} className={classNames({ [classes.contactSellerButton]: withBorder }, tabButtonBorderStyle)}>
      {children}
    </Button>
  </Container>
);
