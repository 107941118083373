import React, { ComponentProps } from 'react';
import { Stack } from '@fiverr-private/layout_components';
import { VettedProBadge } from '@fiverr-private/badges';

export const DATA_TEST_ID = 'pro-badge';

interface ProBadgeProps extends ComponentProps<typeof VettedProBadge> {
  className?: string;
  useTestId?: boolean;
}

export const ProBadge: React.FC<ProBadgeProps> = ({ className, tooltipPosition = 'top', useTestId = false }) => {
  const dataTestId = useTestId ? DATA_TEST_ID : undefined;

  return (
    <Stack className={className} alignItems="center" width="fit-content">
      <VettedProBadge tooltipPosition={tooltipPosition} dataTestId={dataTestId} />
    </Stack>
  );
};
