import { CONTACT_OPTIONS_TYPE } from '../constants';
import { isGigPaused } from '../../../utils/gigStatus';

/**
 * determine which contact option type to render upon the user test:
 * available options are:
 * 1 "get a quote" button
 * 2 "contact seller" button
 * 3 "get a quote" button and "contact seller" button
 * 4 none of the above
 *
 * Consultation button also can be added to the dropdown upon consultation=true query param.
 *
 * - When there is contact seller and get a quote (customOrderEligible = true):
 *      group A and b: we show both (option 3)
 *      group C: keep only "get a quote" button (option 1)
 * - When there is only contact seller (customOrderEligible = false):
 *      group A and b: keep the "contact seller" button (option 2)
 *      group C: render nothing (option 4)
 *
 *  if floating chat is enabled and consultation button is disabled, we show only contact seller button
 *
 * @param {boolean} customOrderEligible
 * @param {boolean} consultationEligible
 * @param {boolean} inAIBriefTest
 * @param {boolean | undefined} isFloatingChatEnabled
 * @param {boolean} isConsultationButtonDisabled
 * @returns {string}
 */
export const getContactOptionsType = (customOrderEligible, consultationEligible, inAIBriefTest, isFloatingChatEnabled, isConsultationButtonDisabled) =>
    ((!inAIBriefTest && customOrderEligible) || consultationEligible) && !(isFloatingChatEnabled && isConsultationButtonDisabled)
        ? CONTACT_OPTIONS_TYPE.DROPDOWN
        : CONTACT_OPTIONS_TYPE.CONTACT_SELLER_ONLY;

/**
 * Returns whether to display the contact seller button.
 * @param {object} general
 * @param {boolean} useStickyLayout
 * @returns whether or not to display the contact seller button.
 */
export const shouldRenderContactSeller = ({ general, useStickyLayout }) => {
    const { gigStatus, traffiqed, isLimitedEdition, isOnVacation } = general;
    const isPaused = isGigPaused(gigStatus, traffiqed);

    return useStickyLayout && !isLimitedEdition && !isOnVacation && !isPaused;
};
