import { useGigPageContext } from '@fiverr-private/gig_page_context';
import { ROLLOUTS } from '../../types/rollouts';
import { Seller } from '../../types/seller';

export const useGetSellerEligibility = (seller: Seller) => {
    const {
        seller: { isPro, isAgency, sellerHourlyEligibility },
        rollouts,
    } = useGigPageContext();

    const hasHourlyRate = !!seller.hourlyRate;

    if (!hasHourlyRate) return false;

    if (sellerHourlyEligibility !== undefined) {
        return sellerHourlyEligibility;
    }

    const isZebrasHourlyRatesRollout = rollouts[ROLLOUTS.ZEBRAS_HOURLY_RATES];

    return !!isPro || isAgency || isZebrasHourlyRatesRollout;
};
