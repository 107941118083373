import React, { createRef, PureComponent } from 'react';
import { object, array, string, bool, oneOf } from 'prop-types';
import classNames from 'classnames';
import { Swipeable } from 'react-swipeable';
import { groupBy, findIndex, merge } from 'lodash';
import Slideshow from '@fiverr-private/slideshow/src/Slideshow';
import { getContext } from '@fiverr-private/fiverr_context';
import { LazyComponent } from '@fiverr-private/orca';
import { isNonExperientialGigTheme } from '../../../utils/theme/isNonExperientialGigTheme';
import { metric } from '../../../utils/metric';
import { GIG_GALLERY } from '../../../utils/events';
import {
    MP_CLICKED_GALLERY_FULL_SCREEN,
    MP_CLICKED_NEXT_SLIDE,
    MP_CLICKED_PREVIOUS_SLIDE,
    MP_CLICKED_GALLERY_THUMBNAIL,
} from '../../../utils/mixPanelEvents';
import * as Slides from './slides';
import GalleryThumbnails from './GalleryThumbnails';
import { keyCode, NON_EXP_BQ_CLICK_ZOOM_PARAMS } from './constants';
import IndicationDots from './IndicationDots';

import './style.scss';

// The number of images ahead of the current slider to preload...
const LOAD_AHEAD = 1;

class GigGallery extends PureComponent {
    constructor(props) {
        super(props);

        const {
            isTouch,
            queryParameters: { attachment_id: attachmentId },
        } = getContext();

        this.state = {
            currSlideIndex: this.getInitialSlideIndex({ attachmentId }),
            isTouch,
            shouldImportGalleryModal: false,
        };

        // Refs
        this.componentWrapper = null;
        this.openModal = createRef();

        this.slideComponents = [];

        // Binding
        this.renderSlide = this.renderSlide.bind(this);
        this.changeSlide = this.changeSlide.bind(this);
        this.handleSlideClick = this.handleSlideClick.bind(this);
        this.handlePrev = this.handlePrev.bind(this);
        this.handleNext = this.handleNext.bind(this);
        this.handleThumbnailChange = this.handleThumbnailChange.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.setOpenModal = this.setOpenModal.bind(this);
        this.slideClickBqHandler = this.slideClickBqHandler.bind(this);
    }

    getInitialSlideIndex = ({ attachmentId }) => {
        // If attachmentId is not exist then set initial index at 0.
        if (!attachmentId) {
            return 0;
        }

        const { slides } = this.props;
        const attachmentIndex = findIndex(slides, { attachmentId });

        // If passed attachmentId doesn't exist in the slides then set initial index at 0.
        if (attachmentIndex === -1) {
            return 0;
        }

        return attachmentIndex;
    };

    changeSlide(index, callback) {
        const { length: len } = this.props.slides;
        let nextSlide = index;

        if (nextSlide < 0) {
            nextSlide = len - 1;
        }
        if (nextSlide >= len || typeof index !== 'number') {
            nextSlide = 0;
        }

        this.setState(
            {
                currSlideIndex: nextSlide,
            },
            callback
        );

        this.componentWrapper && this.componentWrapper.focus({ preventScroll: true });
    }

    handlePrev(e) {
        const { biEvents } = this.context;
        e && e.preventDefault && e.preventDefault();
        this.changeSlide(this.state.currSlideIndex - 1, () => {
            biEvents.sendBigQueryEvent({
                eventName: GIG_GALLERY,
                params: { type: 'clicked_previous', source: 'gig page' },
            });
        });
        this.reportToMixPanel(MP_CLICKED_PREVIOUS_SLIDE);
    }

    handleNext(e) {
        const { biEvents } = this.context;
        e && e.preventDefault && e.preventDefault();
        this.changeSlide(this.state.currSlideIndex + 1, () => {
            biEvents.sendBigQueryEvent({
                eventName: GIG_GALLERY,
                params: { type: 'clicked_next', source: 'gig page' },
            });
        });
        this.reportToMixPanel(MP_CLICKED_NEXT_SLIDE);
    }

    handleThumbnailChange(idx) {
        const { biEvents } = this.context;
        const { isFullView } = this.props;

        this.changeSlide(idx, () => {
            biEvents.sendBigQueryEvent({
                eventName: GIG_GALLERY,
                params: { type: 'clicked_image', source: 'zoom_popup' },
            });
        });
        this.reportToMixPanel(MP_CLICKED_GALLERY_THUMBNAIL);
        !isFullView && this.handleSlideClick(idx);
    }

    slideClickBqHandler({ orderId, typeImage, typeVideo }) {
        const { gigTheme, biEvents } = this.context;

        if (!isNonExperientialGigTheme(gigTheme)) {
            biEvents.sendBigQueryEvent({
                eventName: GIG_GALLERY,
                params: { type: 'clicked_zoom', source: 'gig page' },
            });

            return;
        }

        const getFileType = ({ orderId, typeImage, typeVideo }) => {
            if (orderId) {
                return 'delivery';
            }
            if (typeImage) {
                return 'image';
            }

            if (typeVideo) {
                return 'video';
            }

            return 'other';
        };

        const baseData = biEvents.getBigQueryEnrichmentData();

        biEvents.sendRawBigQueryEvent(
            merge({}, NON_EXP_BQ_CLICK_ZOOM_PARAMS, {
                user: { id: baseData.userId },
                seller: { id: baseData.sellerId },
                gig: { id: baseData.gigId },
                page: { ctx_id: baseData.pageCtxId },
                gallery: {
                    item: getFileType({ orderId, typeImage, typeVideo }),
                },
            })
        );
    }

    handleSlideClick(index) {
        const slide = this.props.slides[index];
        const { isFullView } = this.props;

        if (slide.typeImage || slide.typePdf || (slide.typeAudio && !isFullView) || (slide.typeVideo && !isFullView)) {
            this.slideClickBqHandler(slide);
            this.reportToMixPanel(MP_CLICKED_GALLERY_FULL_SCREEN);
            metric.count('gig_gallery.click');

            if (this.openModal.current) {
                this.openModal.current();
            } else {
                this.setState({
                    shouldImportGalleryModal: true,
                });
            }
        }
    }

    handleKeyDown(e) {
        if (!e) {
            return;
        }
        e.keyCode === keyCode.ARROW_LEFT && this.handlePrev(e);
        e.keyCode === keyCode.ARROW_RIGHT && this.handleNext(e);
    }

    reportToMixPanel(eventName) {
        const { slides } = this.props;
        const { biEvents } = this.context;
        const types = groupBy(slides, (slide) => slide.itemScopeType);
        const { ImageSlide, VideoSlide, PdfSlide, AudioSlide } = types;

        biEvents.sendMixPanelEvent({
            eventName,
            params: {
                'Number of items': slides.length,
                image: ImageSlide ? ImageSlide.length : 0,
                video: VideoSlide ? VideoSlide.length : 0,
                pdf: PdfSlide ? PdfSlide.length : 0,
                audio: AudioSlide ? AudioSlide.length : 0,
            },
        });
    }

    setOpenModal(open) {
        this.openModal.current = open;
    }

    renderSlide(slide, index) {
        const { currSlideIndex } = this.state,
            slideProps = {
                slide,
                key: `${index}--${slide.media.medium || slide.src}`,
                isActive: index === currSlideIndex,
                expanded: false,
                index,

                // For now, this is pretty dumb "one ahead" preloader, if we need to make it more
                // complex we need to move this logic to its own function.
                preload: index > currSlideIndex && index <= currSlideIndex + LOAD_AHEAD,
            };

        if (this.slideComponents[index]) {
            const { isActive, preload, slide } = this.slideComponents[index].props;
            if (isActive === slideProps.isActive && preload === slideProps.preload && slide.src === slideProps.src) {
                return this.slideComponents[index];
            }
        }

        const SlideComponent = Slides[slide.itemScopeType];
        return SlideComponent ? <SlideComponent {...slideProps} /> : null;
    }

    lazyImportGalleryModal() {
        return () => import(/* webpackChunkName: 'GalleryModal' */ './GalleryModal');
    }

    onLazyImportGalleryModalError(error) {
        this.context.logger.error(error, {
            description: 'Failed to lazy load gig gallery modal',
        });
    }

    render() {
        const { transition, slides, isFullView } = this.props;
        const { isTouch, shouldImportGalleryModal } = this.state;
        const hasSlides = !!slides.length,
            { currSlideIndex } = this.state,
            arrows = [
                <a key="ss-prev" href="#" className="nav-prev" onClick={this.handlePrev} rel="nofollow" />,
                <a key="ss-next" href="#" className="nav-next" onClick={this.handleNext} rel="nofollow" />,
            ];
        this.slideComponents = slides.map(this.renderSlide);
        const sectionClasses = classNames('gig-gallery-component', { touch: isTouch });

        if (!hasSlides) {
            return null;
        }

        const galleryModalProps = {
            slides,
            selectedIndex: currSlideIndex,
            transition: 'fade-slide-h',
            onClose: this.changeSlide,
            trigger: this.setOpenModal,
        };

        const gallerySlideshowClassnames = classNames('gallery-slideshow', {
            'minimal-view': !isFullView,
        });

        return (
            <section
                className={sectionClasses}
                ref={(el) => (this.componentWrapper = el)}
                onKeyDown={this.handleKeyDown}
                tabIndex={-1}
            >
                <div className={gallerySlideshowClassnames}>
                    {slides.length > 1 && arrows}
                    <Swipeable
                        onSwipedLeft={this.handleNext}
                        onSwipedRight={this.handlePrev}
                        preventDefaultTouchmoveEvent
                        className="swipe-events-wrapper"
                    >
                        <Slideshow
                            selectedIndex={currSlideIndex}
                            transition={transition}
                            onClick={this.handleSlideClick}
                        >
                            {this.slideComponents}
                        </Slideshow>
                    </Swipeable>
                </div>
                <IndicationDots count={slides.length} current={currSlideIndex} />
                <GalleryThumbnails
                    slides={slides}
                    selectedIndex={currSlideIndex}
                    onChange={this.handleThumbnailChange}
                />
                <LazyComponent
                    lazyImport={this.lazyImportGalleryModal()}
                    shouldImport={shouldImportGalleryModal}
                    onError={this.onLazyImportGalleryModalError}
                    componentProps={galleryModalProps}
                />
            </section>
        );
    }
}

GigGallery.propTypes = {
    slides: array.isRequired,
    transition: string,
    isTouch: bool,
    isFullView: bool.isRequired,
};

GigGallery.defaultProps = {
    transition: 'slide-h',
    isTouch: false,
};

GigGallery.contextTypes = {
    biEvents: object,
    logger: object,
    gigTheme: oneOf(['experiential', 'nonExperiential']),
};

export default GigGallery;
