import { Click, ClickType, ElementType, Impression } from '@fiverr-private/bianka_js';
import { bianka } from '@fiverr-private/obs';
import { getContext } from '@fiverr-private/fiverr_context';
import { PAGE_NAME } from '../../events';

export const reportHourlyCardClick = () => {
    const { pageCtxId, userId, organizationId } = getContext();

    bianka.reportActivity<Click>({
        type: Click,
        event: {
            type: 'clicked_request_an_hourly_offer_gig_page',
            clickType: ClickType.LEFT,
            user: {
                id: userId as number,
            },
            element: {
                name: 'request_an_hourly_offer',
                type: ElementType.BUTTON,
                numberOfElements: 1,
            },
            page: {
                ctxId: pageCtxId,
                name: PAGE_NAME,
            },
            organization: {
                id: organizationId as string,
            },
        },
    });
};

export const reportHourlyCardImpression = () => {
    const { pageCtxId, userId, organizationId } = getContext();

    bianka.reportActivity<Impression>({
        type: Impression,
        event: {
            type: 'viewed_request_an_hourly_offer_gig_page',
            user: {
                id: userId as number,
            },
            page: {
                ctxId: pageCtxId,
                name: PAGE_NAME,
            },
            organization: {
                id: organizationId as string,
            },
        },
    });
};
