import React from 'react';
import { object } from 'prop-types';
import { I18n, translate } from '@fiverr-private/i18n-react';
import { Container } from '@fiverr-private/layout_components';
import { getContext } from '@fiverr-private/fiverr_context';
import { useGigPageContext } from '@fiverr-private/gig_page_context';
import ContactTrigger from '../../../shared/ContactTrigger';
import { reportContactSellerClick } from '../../../../utils/contactSeller';
import { GradientBorderButton } from '../../../shared/GradientBorderButton/GradientBorderButton';

const bqSourceName = 'about_the_seller';

const ContactMe = () => {
    const { isMobile } = getContext();
    const { biEvents, general, seller, isFloatingChatEnabled } = useGigPageContext();
    const { isAgency } = seller;
    const ctaTitle = isAgency ? `gig_page_perseus.seller_card.contact_us` : `gig_page_perseus.seller_card.contact_me`;

    const reportContactMeClick = () =>
        reportContactSellerClick({
            biEvents,
            general,
            mixpanelSourceComponent: 'Gig page seller card',
            bqSourceName,
            metricKey: 'contact_me.click',
        });

    return (
        <ContactTrigger
            onClick={reportContactMeClick}
            source="contact-me"
            bqSourceName={bqSourceName}
            triggerId="Contact Seller"
            triggerCopy={translate(ctaTitle)}
            triggerPlacement="Seller section bottom"
        >
            {(triggerProps) => (
                <Container marginTop="5">
                    <GradientBorderButton {...triggerProps} variant="outline" fullWidth={isMobile} withBorder={isFloatingChatEnabled}>
                        <I18n k={ctaTitle} />
                    </GradientBorderButton>
                </Container>
            )}
        </ContactTrigger>
    );
};

ContactMe.contextTypes = {
    biEvents: object,
    general: object,
};

export default ContactMe;
