import React from 'react';
import classNames from 'classnames';
import { object, shape, number, bool, arrayOf } from 'prop-types';
import { getContext } from '@fiverr-private/fiverr_context';
import { translate } from '@fiverr-private/i18n-react';
import { Text } from '@fiverr-private/typography';
import { Carousel } from '@fiverr-private/orca';
import GigCardCarouselWrapper from '../shared/GigCardCarouselWrapper';
import SafePureComponent from '../shared/SafePureComponent';
import { OTHER_GIGS_BY } from '../shared/GigCardCarouselHOC';

import './style.scss';

const OTHER_GIGS_SOURCE = 'other_gigs_by';

const OtherGigsBy = (
    { isTouch, isLoggedIn, otherGigs, studioInfo },
    { pathfinderWrapper, general, isBusinessLayout, isProRebrandingLayout }
) => {
    const formatBoxTitle = () => {
        const { isStudio } = general;
        const { pathParameters } = getContext();
        let sellerName = pathParameters.username;
        let sellerUrl = pathfinderWrapper.pathfinder('short_user', { id: sellerName });

        if (isStudio) {
            const { name, slug } = studioInfo;
            sellerUrl = pathfinderWrapper.pathfinder('studio_page', { slug });
            sellerName = name;
        }

        const linkClasses = classNames({
            'new-pro-layout': isBusinessLayout || isProRebrandingLayout,
        });

        return translate('gig_page_perseus.recommended_gigs.more_services_by.title', {
            templates: {
                link: () => (
                    <a className={linkClasses} href={sellerUrl}>
                        {sellerName}
                    </a>
                ),
            },
        });
    };

    const { gigIds } = otherGigs;

    const sliderBaseNum = 5;
    const sliderBreakpoints = {
        [Carousel.BREAKPOINTS.BP_600]: 1,
        [Carousel.BREAKPOINTS.BP_750]: 2,
        [Carousel.BREAKPOINTS.BP_900]: 2,
        [Carousel.BREAKPOINTS.BP_1000]: 3,
        [Carousel.BREAKPOINTS.BP_1160]: 3,
        [Carousel.BREAKPOINTS.BP_1300]: 4,
    };

    if (!gigIds.length) {
        return null;
    }

    const ajaxUrl = pathfinderWrapper.pathfinder('gig_page_fetch_other_gigs_by_gig_ids_api', { gig_ids: gigIds });

    return (
        <GigCardCarouselWrapper
            type={OTHER_GIGS_BY}
            boxClass="gigs-other-gigs-by"
            url={ajaxUrl}
            carouselPosition={2}
            isLoggedIn={isLoggedIn}
            isTouch={isTouch}
            source={OTHER_GIGS_SOURCE}
            viewportType="gigs.show"
            sliderBaseNum={sliderBaseNum}
            sliderBreakpoints={sliderBreakpoints}
        >
            <Text as="h2" className="section-title">
                {formatBoxTitle()}
            </Text>
        </GigCardCarouselWrapper>
    );
};

OtherGigsBy.propTypes = {
    isTouch: bool,
    isLoggedIn: bool,
    otherGigs: shape({
        gigIds: arrayOf(number),
    }),
    studioInfo: object,
};

OtherGigsBy.contextTypes = {
    pathfinderWrapper: object,
    general: object,
    isBusinessLayout: bool,
    isProRebrandingLayout: bool,
};

export { OtherGigsBy };
export default SafePureComponent(OtherGigsBy);
