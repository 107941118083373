import React from 'react';
import { getContext } from '@fiverr-private/fiverr_context';
import { useGigPageContext } from '@fiverr-private/gig_page_context';
import { translate } from '@fiverr-private/i18n-react';
import { Link } from '@fiverr-private/typography';
import { bqClickedOnCustomerProtection } from './biEvents';

const HREF =
  'https://help-pro.fiverr.com/hc/en-us/articles/21333583493649-Fiverr-Pro-satisfaction-guarantee-for-clients';

export const SatisfactionGuaranteeLink = () => {
  const { biEvents, gigTheme } = useGigPageContext();

  const enrichmentData = biEvents.getBigQueryEnrichmentData();
  const { organizationId } = getContext();

  const onLinkClick = () => {
    biEvents.sendRawBigQueryEvent(
      bqClickedOnCustomerProtection({
        ...enrichmentData,
        organizationId,
        gigTheme,
      })
    );
  };

  return (
    <Link fontSize="b_sm" href={HREF} target="_blank" onClick={onLinkClick}>
      {translate('gig_page_banners.customer_protection_banner.link')}
    </Link>
  );
};
