import React from 'react';
import { isEmpty, isArray } from 'lodash';
import { translate } from '@fiverr-private/i18n-react';
import {
  AiDeliveryBadge,
  NavigationCard,
  Status,
  theme,
  BiEventData,
  ModelGradientProps,
} from '@fiverr-private/go_shared_ui';
import { useGigPageContext } from '@fiverr-private/gig_page_context';
import pathfinder from '@fiverr-private/pathfinder';
import { Stack } from '@fiverr-private/layout_components';
import { Typography } from '@fiverr-private/typography';
import { getContext } from '@fiverr-private/fiverr_context';
import { TooltipContentProps } from './AiDeliveryBadge.types';

export const TooltipContent = ({ aiModels, sellerName, biEventData }: TooltipContentProps) => {
  const aiModel = aiModels[0];

  const title = translate('gig_page_perseus.badges.ai_delivery.tooltip');
  const status = translate('gig_page_perseus.badges.ai_delivery.status');

  const href = pathfinder(
    'ai_hub_playground_create_tab_new_model_conversation',
    {
      seller_username: sellerName,
      model_slug: aiModel.id,
    },
    { query: { source: 'gig_page_badge' } }
  );

  const modelGradient: ModelGradientProps = {
    ...aiModel.theme,
    scale: 'm',
    rotate: 0,
    animate: 'always',
  };

  return (
    <Stack
      direction="column"
      background={theme.colors.grey1250}
      padding="4"
      marginTop="2"
      width="min-content"
      gap="1"
      style={{ borderRadius: '24px' }}
    >
      <Status text={status} />
      <Typography fontSize="b_lg" lineHeight="b_lg" marginBottom="6" color="white">
        {title}
      </Typography>
      <NavigationCard
        url={href}
        cardSize="sm"
        title={aiModel.name}
        modelGradientProps={modelGradient}
        cardBackground={theme.colors.surfaceOne}
        biEventData={biEventData}
        shouldShowButton={true}
      />
    </Stack>
  );
};

export const AiDeliveryBadgeWrapper = () => {
  const { isMobile, userId, pageCtxId } = getContext();
  const {
    aiModels = [],
    sellerUsername,
    general: { sellerId, gigId },
  } = useGigPageContext();

  if (isEmpty(aiModels)) return null;

  const model = aiModels[0];

  // Background should be an array with colors, if it's a string, convert it to an array
  const gradientColors = isArray(model.colors) ? model.colors : [model.colors];

  if (isMobile) {
    return <AiDeliveryBadge gradientColors={gradientColors} />;
  }

  const baseBiEventData: BiEventData = {
    ...(gigId && { gigId }),
    ...(userId && { userId }),
    ...(sellerId && { sellerId }),
    ctxId: pageCtxId,
    pageName: 'gig_page',
    aiModelId: aiModels[0].id,
  };

  const biEventData: BiEventData = {
    ...baseBiEventData,
    componentName: 'badge',
  };

  return (
    <AiDeliveryBadge
      gradientColors={gradientColors}
      popoverContentProps={{ disablePadding: true, backgroundColor: 'transparent' }}
      tooltipContent={<TooltipContent aiModels={aiModels} sellerName={sellerUsername} biEventData={biEventData} />}
      biEventData={baseBiEventData}
    />
  );
};
