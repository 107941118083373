import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { object } from 'prop-types';
import TabContent from '../TabContent';
import { BASIC_PACKAGE_ID } from '../../../utils/packagesUtils/constants';
import { getCurrentPackage } from '../../../reducers/packages';

import './style.scss';

const SinglePackage = ({ packageData, customOrder }) => {
    const tabContentProps = {
        id: BASIC_PACKAGE_ID,
        features: packageData.features,
        customOrder,
    };

    return (
        <div className={classNames('packages-tabs', 'single')}>
            <TabContent {...tabContentProps} />
        </div>
    );
};

SinglePackage.propTypes = {
    packageData: object,
    customOrder: object,
};

const mapStateToProps = (state) => ({
    packageData: getCurrentPackage(state),
    customOrder: state.customOrder,
});

export { SinglePackage };
export default connect(mapStateToProps)(SinglePackage);
